import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ContentHighlightBlock } from 'src/app/model/content-highlight-block';
import { BundleType } from 'library-explorer';
import { HighlightBlockContentType } from 'src/app/model/enums/highlight-block-content-type.enum';
import { SettingsService } from '@app/services/settings.service';
import { PayableNodeNavigationService } from 'src/app/services/payable-node-navigation.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { MobileCardStyleType } from '@app/model/enums/mobile-card-style.enum';

@Component({
  selector: 'app-content-highlight-block',
  templateUrl: './content-highlight-block.component.html',
  styleUrls: ['./content-highlight-block.component.scss']
})
export class ContentHighlightBlockComponent implements OnInit {

  @ViewChild('slickCarousel') slickCarousel: SlickCarouselComponent;

  @Input() public set block(value: ContentHighlightBlock) {
    if (value) {
      this._block = value;
      this.title = value.title;

      this.getContentHighlights(value);
    }
  }

  public get block() {
    return this._block;
  }

  public items: any[] = [];
  public title: string;

  public isLoading = true;

  public mousePosition: any = {};
  public carouselConfig = {
    lazyLoad: 'ondemand',
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true,
    touchMove: true,
    swipeToSlide: true,
    autoplay: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  public isFirstSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLastSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly bundleTypes: typeof BundleType = BundleType;
  public readonly mobileCardStyleTypes: typeof MobileCardStyleType = MobileCardStyleType;

  public isPaymentEnabled = false;
  public skipModule = false;
  public showCarouselBtns = false;
  private _block: ContentHighlightBlock;

  constructor(
    private readonly settingsService: SettingsService,
    private readonly paymentService: PaymentService,
    private readonly router: Router,
    private readonly payableNodeNavigationService: PayableNodeNavigationService
  ) { }

  ngOnInit() {
    this.settingsService.getSettings().subscribe(data => {
      this.skipModule = data.system.skip_module;
    });

    this.paymentService.getPaymentConfig().subscribe(res => {
      this.isPaymentEnabled = !!res.enabled;
    });
  }

  public breakpoint(event) {
    const slidesToShow = event.slick.slickGetOption('slidesToShow');
    const slideCount = event.slick.slideCount;

    this.showCarouselBtns = slidesToShow < slideCount;
  }

  public afterChange(event): void {
    setTimeout(() => {
      const slidesToShow = event.slick.slickGetOption('slidesToShow');
      const slideCount = event.slick.slideCount;
      const currentSlide = event.slick.currentSlide;

      this.isFirstSlide.next(currentSlide === 0);
      this.isLastSlide.next(currentSlide + slidesToShow >= event.slick.slideCount);

      this.showCarouselBtns = slidesToShow < slideCount;
    });
  }

  public slickInit(event): void {
    this.afterChange(event);
  }

  public navigate(event, item: any): void {
    if (
      this.block.sliderEnabled
      && (
        Math.abs(this.mousePosition.x - event.screenX) > 10
        || Math.abs(this.mousePosition.y - event.screenY) > 10
      )
    ) {
      return;
    }

    switch (item.bundle) {
      case BundleType.CLASS:
        this.router.navigate(['/courses'], { queryParams: { classID: item.id }, state: { autoOpen: true }});
        break;
      case BundleType.COURSE:
        const data = {
          ...item,
          bundle: BundleType.COURSE,
          uuid: item.id
        };
        this.payableNodeNavigationService.navigate(data, event, this.mousePosition);

        break;
      case BundleType.MODULE:
        this.router.navigate(['/lessons'], { 
          queryParams: { moduleID: item.id, courseID: item.parentCourseId }, 
          state: { autoOpen: true }});
        break;
      case BundleType.LESSON:
      case BundleType.QUIZ:
        this.router.navigate(['/chapters'], { 
          queryParams: { lessonID: item.id, courseID: item.parentCourseId }, 
          state: { autoOpen: true }});
        break;
    }
  }

  public openDetails(item: any): void {
    this.payableNodeNavigationService.openCourseDetails(item, true).subscribe();
  }

  public onMouseDown(event): void {
    this.mousePosition.x = event.screenX;
    this.mousePosition.y = event.screenY;
  }

  private getContentHighlights(value: ContentHighlightBlock): void {
    switch (value.gettingContentType) {
      case HighlightBlockContentType.AUTOMATIC:
      case HighlightBlockContentType.MANUAL:
      case HighlightBlockContentType.AUTOMATIC_IN_PROGRESS:
        this.items = value.automaticContentItems || [];
        break;
      case HighlightBlockContentType.MANUAL_SELECTION:
        this.items = value.contents || [];
        break;
    }
  }
}
