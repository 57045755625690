import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@app/services/settings.service';
import { BundleType, ImageModel } from 'library-explorer';

@Component({
  selector: 'app-catalog-page-banner',
  templateUrl: './catalog-page-banner.component.html',
  styleUrls: ['./catalog-page-banner.component.scss']
})
export class CatalogPageBannerComponent implements OnInit {
  public pageSettings: { title: string, image: ImageModel, phoneImage: ImageModel };

  public searchBundles = [BundleType.CLASS, BundleType.COURSE, BundleType.MODULE, BundleType.LESSON, BundleType.QUIZ];

  constructor(
    private readonly router: Router,
    private readonly settingsService: SettingsService) { }

  ngOnInit(): void {
    this.settingsService.getSettings()
      .subscribe(data => {
        this.pageSettings = data.class;

        this.searchBundles = this.searchBundles.filter(bundle => {
          if (data.system.skip_module) {
            return bundle !== BundleType.MODULE;
          }

          if (data.class.hideClassLevel) {
            return bundle !== BundleType.CLASS;
          }

          return true;
        });
      });
  }

  public navigateSearchPage(search = ''): void {
    this.router.navigate(['/catalog-search'], { queryParams: { search }});
  }

}
