<div *ngIf="certificate" class="certificate-card">
  <div class="certificate-card__body">
    <div class="certificate-card__body--icon">
      <lib-icon icon="certificate" size="32"></lib-icon>
    </div>
    <div class="certificate-card__body--title-wrapper">
      <div class="certificate-card__body--title">
        {{certificate.title}}
      </div>
      <div class="certificate-card__body--subtitle">
        {{'COMMON.granted' | translate}} {{certificate.dateOfCompletion | customDate}}
      </div>
    </div>
  </div>
  <div class="certificate-card__buttons">
    <button *ngIf="false" mat-icon-button class="button button--icon" (click)="open()">
      <lib-icon icon="eye"></lib-icon>
    </button>
    <button *ngIf="shareEnabled" mat-icon-button class="button button--icon" (click)="share()">
      <lib-icon icon="share-network"></lib-icon>
    </button>
    <button mat-icon-button class="button button--icon" (click)="download()">
      <lib-icon icon="download-simple"></lib-icon>
    </button>
  </div>
  <div class="certificate-card__more">
    <button mat-icon-button class="button button--icon button--icon-extra-small" [matMenuTriggerFor]="certificateMenu">
      <lib-icon icon="dots-three-vertical"></lib-icon>
    </button>

    <mat-menu #certificateMenu="matMenu" class="base-context-menu">
      <button *ngIf="false" mat-menu-item class="base-menu-button" (click)="open()">
        <lib-icon icon="eye"></lib-icon> {{'COMMON.preview' | translate}}
      </button>
      <button *ngIf="shareEnabled" mat-menu-item class="base-menu-button" (click)="share()">
        <lib-icon icon="share-network"></lib-icon> {{'COMMON.share' | translate}}
      </button>
      <button mat-menu-item class="base-menu-button" (click)="download()">
        <lib-icon icon="download-simple"></lib-icon> {{'COMMON.download' | translate}}
      </button>
    </mat-menu>
  </div>
</div>
