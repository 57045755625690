import { Inject, Injectable } from '@angular/core';
import { STORAGE_KEYS } from '../constants/injection-tokens';
import { Capacitor } from '@capacitor/core';
import { AppTrackingStatusResponse, AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProviderType, PaymentModeEnum, CookieConsentAgreement, CookieCategory, LegalDocumentModel } from '../models';
import { StorageKeys } from '../models/storage-keys';
import { EmbedVideoUsageService } from './embed-video-usage-api.service';
import { TranslateService } from '@ngx-translate/core';


declare var initCookieConsent: any;

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  private readonly revision = 1.2;
  private cookieConsent: any;
  private cookieConsentAgreement: BehaviorSubject<CookieConsentAgreement | null> = new BehaviorSubject<CookieConsentAgreement | null>(null);

  private get isBot() {
    return navigator && ((navigator.userAgent && /bot|crawl|spider|slurp|teoma/i.test(navigator.userAgent)) || navigator.webdriver);
  }

  private cookieDefault = {
    categories: Object.values(CookieCategory),
    level: Object.values(CookieCategory),
    consent_date: new Date()
  };

  constructor(
    private readonly embedVideoUsageService: EmbedVideoUsageService,
    private readonly translateService: TranslateService,
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys) {
  }

  public getCookieConsentAgreement(): Observable<CookieConsentAgreement | null> {
    return this.cookieConsentAgreement.asObservable();
  }

  public isCookieConsentAgreed(category: CookieCategory): boolean {
    const cookieConsentAgreement = this.cookieConsentAgreement.value;
    if (!cookieConsentAgreement) {
      return false;
    }

    return cookieConsentAgreement.categories.indexOf(category) !== -1;
  }

  public async init(settings: any, documents: LegalDocumentModel[] = []): Promise<void> {
    const isIosPlatform = Capacitor.getPlatform() === 'ios';

    if (isIosPlatform) {
      const trackingTransparecy = await this.checkTrackingTransparency();

      if (trackingTransparecy.status === 'authorized') {
        this.cookieConsentAgreement.next(this.cookieDefault);
      }

      return;
    }

    if (this.isBot) {
      // Cypress
      this.cookieConsentAgreement.next(this.cookieDefault);

      return;
    }

    this.openCookieConsentDialog(settings, documents);
  }

  public openCookiePreferencesSettings(): void {
    this.cookieConsent.showSettings();
  }

  private async openCookieConsentDialog(settings: any, documents: LegalDocumentModel[] = []): Promise<any> {
    if (!initCookieConsent) {
      return;
    }

    const dialogTitle = await this.translateService.get('COOKIES.cookies_policy').toPromise();
    this.cookieConsent = initCookieConsent();

    const documentsLinks = documents.map(item => {
      return `<a href="/document/${item.groupId}" target="_blank" class="cc-link">${item.title}</a>`;
    }).join(', ');

    this.cookieConsent.run({
      current_lang: this.translateService.currentLang,
      cookie_name: this.storageKeys.COOKIE_CONSENT,
      cookie_expiration: 365,
      revision: this.revision,
      autoclear_cookies: true,
      onFirstAction: (_: any, cookieConsentAgreement: CookieConsentAgreement) => {
        this.cookieConsentAgreement.next(cookieConsentAgreement);
      },
      onAccept: (cookieConsentAgreement: CookieConsentAgreement) => {
        this.cookieConsentAgreement.next(cookieConsentAgreement);
      },
      languages: {
        [this.translateService.currentLang]: {
          consent_modal: {
            title: dialogTitle,
            description: `
            ${this.translateService.instant('COOKIE_CONSENT.website_uses_essential_cookies')}
            <button type="button" data-cc="c-settings" class="cc-link">${this.translateService.instant('COOKIS_CONSENT.let_me_choose')}</button>`,
            primary_btn: {
              text: this.translateService.instant('COOKIE_CONSENT.accept_all'),
              role: 'accept_all'
            },
            secondary_btn: {
              text: this.translateService.instant('COOKIE_CONSENT.settings'),
              role: 'settings'
            }
          },
          settings_modal: {
            title: this.translateService.instant('COOKIE_CONSENT.cookie_preferences'),
            save_settings_btn: this.translateService.instant('COOKIE_CONSENT.save_settings'),
            accept_all_btn: this.translateService.instant('COOKIE_CONSENT.accept_all'),
            close_btn_label: this.translateService.instant('COOKIE_CONSENT.close'),
            reject_all_btn: this.translateService.instant('COOKIE_CONSENT.deny_all'),

            cookie_table_headers: [
              { name: this.translateService.instant('COOKIE_CONSENT.name') },
              { domain: this.translateService.instant('COOKIE_CONSENT.domain') },
              { exp: this.translateService.instant('COOKIE_CONSENT.expiration') },
              { description: this.translateService.instant('COOKIE_CONSENT.description') }
            ],
            blocks: [
              {
                title: this.translateService.instant('COOKIE_CONSENT.cookie_usage'),
                description: `
                ${this.translateService.instant('COOKIE_CONSENT.we_user_cookies_to_ensure')}
                ${documentsLinks.length ? this.translateService.instant('COOKIE_CONSENT.for_more_details_relative') : ''}
                ${documentsLinks}`
              }, {
                title: this.translateService.instant('COOKIE_CONSENT.strictly_necessary_cookies'),
                description: this.translateService.instant('COOKIE_CONSENT.strictly_necessary_cookies_description'),
                toggle: {
                  value: CookieCategory.NECCESSARY,
                  enabled: true,
                  readonly: true
                },
                cookie_table: [
                  ...this.getNeccessaryCookies(settings)
                ]
              }, {
                title: this.translateService.instant('COOKIE_CONSENT.anylytics_data'),
                description: this.translateService.instant('COOKIE_CONSENT.analytics_data_description'),
                toggle: {
                  value: CookieCategory.ANALYTICS,
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  ...await this.getAnalyticsCookies(settings)
                ]
              }
            ]
          }
        }
      }
    });
  }

  private getNeccessaryCookies(settings: any): { name: string, domain: string, exp: string, description: string }[] {
    return [
      {
        name: this.storageKeys.REFRESH_TOKEN,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.session-key')
      },
      {
        name: this.storageKeys.LOGGED_IN_USER,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.logged_user_identificator')
      },
      {
        name: this.storageKeys.COOKIE_CONSENT,
        exp: this.translateService.instant('COMMON.days-short-format', { days: 365 }),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.cookie_consent_key')
      },
      {
        name: this.storageKeys.CLASS_LAYOUT,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.class_layout_key')
      },
      {
        name: this.storageKeys.PLATFORM_LANGUAGE,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.platform_language_key')
      },
      {
        name: this.storageKeys.SCORM_SUSPEND_DATA,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.scorm_suspend_data')
      },
      {
        name: this.storageKeys.LIBRARY_LIST_LAYOUT,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.library_layout_key')
      },
      {
        name: this.storageKeys.BRANCH_NAME,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.branch_name')
      },
      {
        name: this.storageKeys.OPENED_LESSON_GROUPS,
        exp: this.translateService.instant('COOKIE_CONSENT.session'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.opened_lesson_groups')
      },
      {
        name: this.storageKeys.USER_SPENT_TIME,
        exp: this.translateService.instant('COOKIE_CONSENT.session'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.user_spent_time')
      },
      ...this.getDownloadForOfflineCookies(settings),
      ...this.getPaymentlCookies(settings),
      {
        name: 'pdfjs.history',
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.pdfjs_history')
      },
    ]
  }

  private async getAnalyticsCookies(settings: any): Promise<{ name: string, domain: string, exp: string, description: string }[]> {
    const analyticsCookies = [
      {
        name: 'cache-sprite-plyr',
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.cookie_necessary_for_cache_function')
      }
    ];

    const youtubeVideoUsage = await this.embedVideoUsageService.getVideoProviderUsage(ProviderType.YOUTUBE).toPromise();

    if (youtubeVideoUsage?.isUsing) {
      analyticsCookies.unshift({
        name: 'PREF',
        domain: 'youtube.com',
        exp: this.translateService.instant('COMMON.days-short-format', { days: 639 }),
        description: this.translateService.instant('COOKIE_CONSENT.recording_your_preferences_and_other_information')
      },
      {
        name: 'GPS',
        domain: 'youtube.com',
        exp: this.translateService.instant('COMMON.hours_full_format', { hours: 1 }),
        description: this.translateService.instant('COOKIE_CONSENT.detecting_unique_id')
      },
      {
        name: 'VISITOR_INFO1_LIVE',
        domain: 'youtube.com',
        exp: this.translateService.instant('COMMON.days-short-format', { days: 90 }),
        description: this.translateService.instant('COOKIE_CONSENT.cookie_used_as_unique_identifier')
      },
      {
        name: 'YSC',
        domain: 'youtube.com',
        exp: this.translateService.instant('COOKIE_CONSENT.session'),
        description: this.translateService.instant('COOKIE_CONSENT.tracking_view_embedded_videos')
      });
    }

    const vimeoVideoUsage = await this.embedVideoUsageService.getVideoProviderUsage(ProviderType.VIMEO).toPromise();

    if (vimeoVideoUsage?.isUsing) {
      analyticsCookies.unshift({
        name: 'sync_active',
        domain: 'player.vimeo.com',
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        description: this.translateService.instant('COOKIE_CONSENT.contains_data_on_visitors_video')
      });
    }

    if (settings.googleAnalytics?.tagManagerId || settings.googleAnalytics?.trackingId) {
      analyticsCookies.unshift({
        name: '^_ga',
        domain: 'google.com',
        exp: this.translateService.instant('COMMON.year-long-format', { years: 2 }),
        description: this.translateService.instant('COOKIE_CONSENT.used_to_distinguish_users')
      },
      {
        name: '^_ga_[container-id]',
        domain: 'google.com',
        exp: this.translateService.instant('COMMON.year-long-format', { years: 2 }),
        description: this.translateService.instant('COOKIE_CONSENT.used_to_persist_session_state')
      })
    }

    return analyticsCookies;
  }

  private getPaymentlCookies(settings: any): { name: string, exp: string, domain: string, description: string }[] {
    if (!settings) {
      return [];
    }

    const additional = [];

    const stripeEnabled = settings.payment.mode === PaymentModeEnum.STRIPE;

    if (stripeEnabled) {
      additional.push({
        name: '__stripe_sid',
        exp: this.translateService.instant('COMMON.days-short-format', { days: 365 }),
        domain: '.stripe.com',
        description: this.translateService.instant('COOKIE_CONSENT.used_provide_fraud_prevention')
      },
        {
          name: '__stripe_mid',
          exp: this.translateService.instant('COMMON.days-short-format', { days: 365 }),
          domain: '.stripe.com',
          description: this.translateService.instant('COOKIE_CONSENT.used_provide_fraud_prevention')
        },
        {
          name: 'recent-views',
          exp: this.translateService.instant('COMMON.days-short-format', { days: 365 }),
          domain: '.stripe.com',
          description: this.translateService.instant('COOKIE_CONSENT.used_to_save_your_preferences')
        });
    }


    return additional;
  }

  private getDownloadForOfflineCookies(settings: any): { name: string, exp: string, domain: string, description: string }[] {
    if (!settings) {
      return [];
    }

    const downloadEnabled = settings.mobileApp?.downloadForOfflineEnabled;

    if (!downloadEnabled) {
      return [];
    }

    return [
      {
        name: this.storageKeys.OFFLINE_USER_DATA,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.offline_user_data')
      },
      {
        name: this.storageKeys.OFFLINE_CLASSES,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.offline_classes')
      },
      {
        name: this.storageKeys.OFFLINE_COURSES,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.offline_courses')
      },
      {
        name: this.storageKeys.PENDING_REQUESTS,
        exp: this.translateService.instant('COOKIE_CONSENT.no_expiration'),
        domain: `${window.location.protocol}//${window.location.host}`,
        description: this.translateService.instant('COOKIE_CONSENT.pending_requests')
      },
    ]
  }

  private async checkTrackingTransparency(): Promise<AppTrackingStatusResponse> {
    let data = await AppTrackingTransparency.getStatus();

    if (data.status !== 'authorized') {
      data = await AppTrackingTransparency.requestPermission();
    }

    return data;
  }
}
