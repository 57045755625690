<div
  class="content-card course-card has-hidden-buttons body-over-media"
  tabindex="0"
  [ngClass]="'bundle-' + data.bundle"
  [class.locked]="data.isLocked"
  [class.extended]="extended"
  [class.disabled]="!data.active"
  [attr.data-cy]="data.bundle + '-card-' + data.id"
  (click)="cardClick.emit($event)">
  <div class="content-card__media" [class.media-21-9]="courseImageRatio === '21:9'">
    <div class="content-card__media--image"
      [class.show-placeholder]="!data?.mediaImage"
      [cropImage]="(data?.mediaImage | preSignedUrl | async) || data.image"
      [crop]="data.mediaImage?.crop"
      imageWidth="400">
    </div>
    <div class="content-card__badges">
      <div
        *ngIf="data.isLocked"
        class="content-card__badges--item badge-dark"
        [matTooltip]="data.selectiveLockingItems?.length
          ? ('COMMON.selective_locked_list' | translate:{content: selectiveLockingItems})
          : ('ERRORS.content_locked' | translate)">
        <lib-icon icon="lock-simple" [size]="16"></lib-icon> {{'MODULE_LESSON_CARD.locked' | translate}}
      </div>
      <div *ngIf="data.isNew && data?.progress !== 100" class="content-card__badges--item badge-blue">
        {{'COMMON.new' | translate}}
      </div>
      <div *ngIf="data.nonMandatory" class="content-card__badges--item badge-orange">
        <mat-icon svgIcon="lms-branch"></mat-icon> {{'COMMON.optional' | translate | titlecase}}
      </div>
    </div>
  </div>
  <div class="content-card__body">
    <div *ngIf="showType" class="content-card__body--label">
      <div class="content-card__body--label-item text-uppercase">
        {{bundleTypeTextMap[data.bundle] | translate}}
      </div>
    </div>
    <div class="content-card__body--title">{{data.title}}</div>
    <div class="content-card__body--footer flex-nowrap align-items-end justify-content-between">
      <div class="content-card__body--footer-list">
        <ng-container *ngIf="!data.progress || data.progress !== 100; else completedFooterItem">
          <div *ngIf="data.payable && !data.isPaid && paymentEnabled" class="content-card__body--footer-item">
            <div class="content-card__price">{{data.price}} {{data.currency | uppercase}}</div>
          </div>
          <div *ngIf="data.progress && !hideProgress" class="content-card__body--footer-item progress-indicator">
            <mat-progress-bar mode="determinate" [value]="data.progress"></mat-progress-bar> {{data.progress}}%
          </div>
          <div *ngIf="skipModule || data.bundle === bundleTypes.MODULE; else modulesCountTemp" class="content-card__body--footer-item">
            <lib-icon icon="stack" [size]="16"></lib-icon> {{data.lessonsCount}} {{'COMMON.lessons' | translate}}
          </div>
          <ng-template #modulesCountTemp>
            <div *ngIf="data.modulesCount" class="content-card__body--footer-item">
              <lib-icon icon="stack" [size]="16"></lib-icon> {{data.modulesCount}} {{'COMMON.modules' | translate}}
            </div>
          </ng-template>
          <div *ngIf="data.time" class="content-card__body--footer-item">
            <lib-icon icon="clock" [size]="16"></lib-icon> {{data.time | minutesToTime : 'short' }}
          </div>
          <div *ngIf="data.leaderboardPoints" [matTooltip]="'COMMON.content_complete_points' | translate:{points: data.leaderboardPoints}" class="content-card__body--label-item">
            <lib-icon icon="currency-eth" [size]="16"></lib-icon> {{data.leaderboardPoints}} {{'LEADERBOARD.pts' | translate | lowercase}}
          </div>
          <div *ngIf="data.achievements?.length" [matTooltip]="'COMMON.content_complete_achievements' | translate:{count: data.achievements?.length, content: achievementsTitle}" class="content-card__body--label-item">
            <lib-icon icon="medal" [size]="16"></lib-icon> {{'COMMON.achievement' | translate}}
          </div>
          <div *ngIf="data.certifications?.length" [matTooltip]="'COMMON.content_complete_certificate' | translate:{ certificate: certificationsTitle }" class="content-card__body--label-item">
            <lib-icon icon="certificate" [size]="16"></lib-icon> {{'COMMON.certificate' | translate}}
          </div>
        </ng-container>
  
        <ng-template #completedFooterItem>
          <div class="content-card__body--footer-item completed-indicator">
            <lib-icon icon="check-circle" [fill]="true"></lib-icon> {{'MODULE_LESSON_CARD.completed' | translate}}
          </div>
        </ng-template>
      </div>
      <ng-container *ngNativeAppConditionalVisibility="false">
        <div *ngIf="availableForDownload" class="content-card__body--footer-download">
          <button mat-icon-button [matTooltip]="statusLabel?.text | translate" (click)="offlineDownloadHandler($event)">
            <lib-icon *ngIf="status !== OfflineDownloadStatusEnum.IN_PROGRESS" [icon]="statusLabel?.icon"></lib-icon>
            <div *ngIf="status === OfflineDownloadStatusEnum.IN_PROGRESS" class="d-flex align-items-center justify-content-center">
              <mat-spinner [diameter]="16"></mat-spinner>
            </div>
          </button>
        </div>
      </ng-container>
    </div>
    <div class="content-card__body--hidden">
      <button *ngIf="!data.isLocked" (click)="buttonClick.emit($event)" mat-flat-button class="button button--primary">
        <ng-container *ngIf="data.payable && !data.isPaid && paymentEnabled; else defaultButtons">
          <lib-icon icon="shopping-cart"></lib-icon>
          <span>{{'COMMON.buy' | translate}} {{data.price}} {{data.currency | uppercase}}</span>
        </ng-container>
        <ng-template #defaultButtons>
          <lib-icon [icon]="data.progress === 100 ? 'arrow-clockwise' : 'play'"></lib-icon>
          <ng-container *ngIf="data.progress === 100">{{'COMMON.start_again' | translate}}</ng-container>
          <ng-container *ngIf="data.progress && data.progress !== 100">{{'COMMON.continue' | translate}}</ng-container>
          <ng-container *ngIf="!data.progress">{{'COMMON.start' | translate}}</ng-container>
        </ng-template>
      </button>
      <button *ngIf="showDetailsButton" (click)="detailsClickEmitter($event)" mat-button class="button button--transparent">{{'LEARNER_CALENDAR.see_event_details' | translate}}</button>
    </div>
  </div>
</div>