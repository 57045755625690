import { InAppBrowser } from '@capgo/inappbrowser';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {
  isInAppBroswerActive: boolean = false;

  constructor(private router: Router, private zone: NgZone) { }

  /**
   * Opens the In-App Browser with URL change listener logic.
   * Navigates within the app if the URL matches the app's hostname.
   * @param url The URL to open in the In-App Browser.
   */
  async openInAppBrowserWithNavigation(url: string): Promise<void> {
    if (!this.isNativePlatformAndPluginAvailable('InAppBrowser')) {
      return;
    }

    await InAppBrowser.open({ url });
    this.addUrlChangeListener();
  }

  /**
   * Opens the In-App Browser without any URL change logic.
   * @param url The URL to open in the In-App Browser.
   */
  async openInAppBrowser(url: string): Promise<void> {
    if (!this.isNativePlatformAndPluginAvailable('InAppBrowser')) {
      return;
    }

    await InAppBrowser.open({ url });
  }


  /**
   * The function `openSystemBrowser` asynchronously opens a system browser with the specified URL.
   * @param {string} url - The `url` parameter is a string that represents the URL of the webpage that
   * you want to open in the system browser.
   * @returns If the `isNativePlatformAndPluginAvailable()` method returns false, then nothing will be
   * returned as the function will exit early. If the method returns true, then the function will open
   * the system browser to the specified URL using the `Browser.open()` method.
   */
  async openSystemBrowser(url: string): Promise<void> {
    if (!this.isNativePlatformAndPluginAvailable('Browser')) {
      return;
    }

    this.isInAppBroswerActive = true;
    await Browser.open({ url });
  }

  /**
   * Closes the System Browser.
   */
  async closeSystemBrowser(): Promise<void> {
    if (!this.isNativePlatformAndPluginAvailable('Browser') || !this.isInAppBroswerActive) {
      return;
    }

    await Browser.close();
    this.isInAppBroswerActive = false
  }

  /**
   * Closes the In-App Browser.
   */
  async closeInAppBrowser(): Promise<void> {
    await InAppBrowser.close();
  }

  async openExternalBrowser(url: string): Promise<void> {
    if (!this.isNativePlatformAndPluginAvailable('AppLauncher')) {
      return;
    }

    await AppLauncher.openUrl({ url });
  }

  /**
   * Adds a URL change listener to the In-App Browser.
   * Closes the browser and navigates if the hostname matches the app's.
   */
  private async addUrlChangeListener(): Promise<void> {
    await InAppBrowser.addListener('urlChangeEvent', (event) => {
      if (!event.url) return;

      const currentHostname = window.location.hostname;
      const targetUrl = new URL(event.url);

      if (targetUrl.hostname === currentHostname) {
        InAppBrowser.close();

        const slug = `${targetUrl.pathname}${targetUrl.search}`;

        // Navigate to the URL within the app
        this.zone.run(() => this.router.navigateByUrl(slug));

        InAppBrowser.removeAllListeners(); // Clean up listeners after navigation
      }
    });
  }

  private isNativePlatformAndPluginAvailable(pluginName: string): boolean {
    return Capacitor.isNativePlatform() && Capacitor.isPluginAvailable(pluginName);
  }

}
