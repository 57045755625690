import { Component, Input, OnInit } from '@angular/core';
import { CertificateService } from '@app/components/certificate/services/certificate.service';
import { UserCertificate } from '@app/model/user-certificate.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-certificate-card',
  templateUrl: './certificate-card.component.html',
  styleUrls: ['./certificate-card.component.scss']
})
export class CertificateCardComponent implements OnInit {

  @Input() certificate: UserCertificate;
  @Input() shareEnabled: boolean;

  public isLoading = false;

  constructor(
    private readonly certificateService: CertificateService
  ) { }

  ngOnInit(): void {
  }

  public share(): void {
    this.certificateService.shareCertificateOnLinkedIn(this.certificate);
  }

  public download(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.certificateService.downloadCerfiticate(this.certificate).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe();
  }

}
