<div
  class="content-card small-card"
  tabindex="0"
  [class.disabled]="!data.active"
  [ngClass]="'bundle-' + data.bundle"
  [attr.data-cy]="data.bundle + '-card-' + data.id"
  (click)="cardClick.emit($event)">
  <div class="content-card__media">
    <div class="content-card__media--image" [class.show-placeholder]="!data?.mediaImage"
      [cropImage]="(data?.mediaImage | preSignedUrl | async) || data.image"
      [crop]="data.mediaImage?.crop"
      imageWidth="200">
    </div>
  </div>
  <div class="content-card__body">
    <div class="content-card__body--title">{{data.title}}</div>
    <ng-container *ngIf="!data.progress || data.progress !== 100; else completedFooterItem">
      <div *ngIf="data.progress && !hideProgress" class="content-card__body--footer-item progress-indicator">
        <mat-progress-bar mode="determinate" [value]="data.progress"></mat-progress-bar> {{data.progress || 30}}%
      </div>
    </ng-container>
  </div>

</div>

<ng-template #completedFooterItem>
  <div class="content-card__body--footer-item completed-indicator">
    <lib-icon icon="check-circle" [fill]="true"></lib-icon> {{'MODULE_LESSON_CARD.completed' | translate}}
  </div>
</ng-template>