import { BundleType } from "../models";

export const bundleTypeTextMapPlural = {
  [BundleType.CLASS]: 'COMMON.classes',
  [BundleType.COURSE]: 'COMMON.courses',
  [BundleType.MODULE]: 'COMMON.modules',
  [BundleType.LESSON]: 'COMMON.lessons',
  [BundleType.QUIZ]: 'COMMON.quizzes',
  [BundleType.LIBRARY]: 'COMMON.library',
  [BundleType.WEBINAR]: 'COMMON.webinars',
  [BundleType.USER]: 'COMMON.users'
}
