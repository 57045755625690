import { Component, Input, OnInit } from '@angular/core';
import { WebinarDTO, OrientationOption, UserAttenderStatus } from 'library-explorer';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  @Input() public item: WebinarDTO;
  @Input() public disabled = false;

  @Input() public orientation: OrientationOption = OrientationOption.VERTICAL;

  public userAttendee = false;

  ngOnInit(): void {
    this.userAttendee = this.item?.attendees?.currentUserStatus === UserAttenderStatus.USER_STATUS_CONFIRMED;
  }
}
