import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MigrationFinalizationData } from '@app/model/migration-finalization-data';
import { ResetPasswordData } from '@app/model/reset-passoword-data';
import { HttpService, PermissionKeys, ProfileUserDTO, OfflineModeService } from 'library-explorer';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Assignment } from 'src/app/model/assignment';
import { CountryModel } from 'src/app/model/country.model';
import { DataResponse } from 'src/app/model/data-response.model';
import { EntitySpentTime } from 'src/app/model/entity-spent-time';
import { LeaderboardGroupTaxonomy } from 'src/app/model/leaderboard-group-taxonomy';
import { MessageResponse } from 'src/app/model/message-response';
import { SearchItemDTO } from 'src/app/model/searchItemDTO.model';
import { UserActivity } from 'src/app/model/user-activity';
import { UserCertificate } from 'src/app/model/user-certificate.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private availableCountries: BehaviorSubject<any>;

  constructor(private httpService: HttpService, private offlineModeService: OfflineModeService) {
  }

  public deleteProfile(id: string): Observable<void> {
    return this.httpService.delete<void>(`users?id=${id}`);
  }

  public resetPassword(email: string, customEndpoint: string): Observable<MessageResponse> {
    return customEndpoint
      ? this.httpService.postThirdParty<any>(customEndpoint, { email })
      : this.httpService.post<MessageResponse>('user/reset/request', { email }, false, true);
  }

  public changePassword(resetPasswordDTO: ResetPasswordData, customEndpoint: string): Observable<string> {
    return customEndpoint
      ? this.httpService.postThirdParty<any>(customEndpoint, resetPasswordDTO)
      : this.httpService.post<string>('user/reset/verify', resetPasswordDTO);
  }

  public getUserById(id: string): Observable<ProfileUserDTO> {
    return this.httpService.get<ProfileUserDTO>(`user/${id}`);
  }

  public getUserCertificates(id: string, entityId?: string): Observable<UserCertificate[]> {
    let params = new HttpParams().set('id', id);
    if (entityId) {
      params = params.set('entityId', entityId);
    }

    return this.httpService.get<UserCertificate[]>(`user-certificates`, params);
  }

  public getUsersAvailableCountries(): Observable<CountryModel[]> {
    if (this.availableCountries && this.availableCountries.value) {
      return of(this.availableCountries.value);
    }

    return this.httpService.get<{ [key: string]: string }>('available-countries', null, true)
      .pipe(
        tap((countries) => this.offlineModeService.storeRequest('available-countries', countries)),
        map(data => Object.keys(data).map(key => ({
          code: key,
          name: data[key]
        }))),
        tap(data => this.availableCountries = new BehaviorSubject(data))
      );
  }

  public getLeaderboardGroups(): Observable<LeaderboardGroupTaxonomy[]> {
    const params: HttpParams = new HttpParams()
      .set('vocabulary', 'leaderboard_group');

    return this.httpService.get<DataResponse<LeaderboardGroupTaxonomy>>('v2/taxonomies', params).pipe(map(res => res.items));
  }

  public getUserAssignmentQuestions(params = new HttpParams()): Observable<Assignment[]> {
    return this.httpService.get<DataResponse<Assignment>>('assignment-questions', params)
      .pipe(
        map(data => data.items)
      );
  }

  public getUserPermissions(id: string): Observable<PermissionKeys[]> {
    const params = new HttpParams().set('id', id);

    return this.httpService.get<PermissionKeys[]>('/user/permission', params);
  }

  public logUserActivity(data: UserActivity): Observable<void> {
    return this.httpService.post('logs/user-activity', data);
  }

  public logSpentTime(data: EntitySpentTime): Observable<void> {
    return this.httpService.post('time-tracker/log-time', data);
  }

  public finalizeMigration(data: MigrationFinalizationData): Observable<any> {
    return this.httpService.post<any>('user/finalize-migration', data);
  }

  public verifyPassword(password: string): Observable<{ status: boolean, message: string}> {
    return this.httpService.post<{ status: boolean, message: string}>('user/verifying-password', { password });
  }

  public clearCache(): void {
    this.availableCountries = null;
  }
}
