import { Directive, HostListener } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SearchDialogComponent } from '@app/shared/components/search-dialog/search-dialog.component';

@Directive({
  selector: '[openSearchDialog]'
})
export class OpenSearchDialogDirective {

  constructor(private readonly dialog: MatDialog) { }

  @HostListener('click')
  public openSearchDialog(): void {
    this.dialog.open(SearchDialogComponent, {
      panelClass: ['dialog-no-padding', 'dialog-base-radius', 'dialog-light-box-shadow', 'mobile-full-width-dialog']
     
    });
  }
}