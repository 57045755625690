<div class="library-details" [class.opened]="libraryItem">
  <div class="library-details-overlay" *ngIf="libraryItem"></div>
  <div class="library-sidebar">
    <ng-container *ngIf="libraryItem">
      <ng-scrollbar class="library-sidebar-scrollbar">
        <div class="library-sidebar-block library-sidebar-title-block">
          <div class="library-sidebar-title">
            <lib-library-item-icon class="library-icon" [item]="libraryItem" [size]="24"></lib-library-item-icon> 
            {{ libraryItem.name }}
          </div>
  
          <button class="button button--icon" mat-icon-button (click)="close()">
            <lib-icon icon="x" [size]="20"></lib-icon>
          </button>
        </div>
    
        <div class="library-sidebar-block library-sidebar-image-block">
          <div 
            class="library-image-preview" 
            [cropImage]="previewImage | preSignedUrl : entityTypeId.NODE : bundleType.LIBRARY : fieldName | async"
            [imageWidth]="1200">
        
            <ng-container *ngIf="!previewImage">
              <lib-library-item-icon class="library-icon" [item]="libraryItem"></lib-library-item-icon>
            </ng-container>
          </div>
        </div>

        <div class="library-sidebar-block" *ngIf="libraryItem.description">
          <h6 class="library-sidebar-block-title">
            {{ 'COMMON.description' | translate }}
          </h6>

          <p class="library-description-paragraph" [innerHTML]="libraryItem.description | safeHtml"></p> 
        </div>
    
        <div class="library-sidebar-block" *ngIf="libraryItem.publicTags?.length"> 
          <h6 class="library-sidebar-block-title">
            {{ 'LIBRARY_PAGE.tags' | translate }}
          </h6>
  
          <lib-chips [items]="libraryItem.publicTags"></lib-chips>
        </div>
    
        <div class="library-sidebar-block">
          <h6 class="library-sidebar-block-title">
            {{ 'LIBRARY_PAGE.details' | translate }}
          </h6>
  
          <div class="library-details-list">
            <div class="library-details-item">
              <span class="library-details-label">{{ 'LIBRARY_PAGE.type' | translate }}</span>
              <span class="library-details-value">{{ libraryItem.file?.extension || libraryItem.type }}</span>
            </div>
  
            <div class="library-details-item" *ngIf="libraryItem.file">
              <span class="library-details-label">{{ 'LIBRARY_PAGE.size' | translate }}</span>
              <span class="library-details-value">{{ +libraryItem.file.fileSize | fileSize }}</span>
            </div>
  
            <div class="library-details-item">
              <span class="library-details-label">{{ 'LIBRARY_PAGE.last_modified' | translate }}</span>
              <span class="library-details-value">{{ libraryItem.updated | date: 'shortDate' }}</span>
            </div>
  
            <div class="library-details-item">
              <span class="library-details-label">{{ 'LIBRARY_PAGE.created' | translate }}</span>
              <span class="library-details-value">{{ libraryItem.created | date: 'shortDate' }}</span>
            </div>
          </div>
        </div>
    
        <div class="library-sidebar-block library-sidebar-actions-block" *ngIf="libraryItem.file">
          <button mat-flat-button class="button button--transparent library-sidebar-button" (click)="download.emit(libraryItem)" *ngIf="allowDownload">
            <lib-icon icon="download-simple" [size]="20"></lib-icon>
            {{ 'LIBRARY_PAGE.download' | translate }}
          </button>
  
          <button mat-flat-button class="button button--transparent library-sidebar-button" (click)="preview.emit(libraryItem)">
            <lib-icon icon="eye" [size]="20"></lib-icon>
            {{ 'LIBRARY_PAGE.preview' | translate }}
          </button>
        </div>
      </ng-scrollbar>
    </ng-container>
  </div>
</div>
