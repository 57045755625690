<section class="learning-path-block block-container" [style.color]="block.textColor">
  <div *ngIf="(showCarouselBtns | async) || block.title" class="learning-path-block__header col-12 px-0 col-xl-8">
    <h1 [innerHtml]="block.title | safeHtml" [rtl]="block.langcode"></h1>
    <div *ngIf="showCarouselBtns | async" class="learning-path-block__arrows" [style.color]="block.textColor">
      <button class="button button--icon button--transparent" mat-icon-button [disabled]="isFirstSlide | async" (click)="slickCarousel?.slickPrev()">
        <lib-icon icon="arrow-left"></lib-icon>
      </button>
      <div class="learning-path-block__arrows--slide-number">
        {{currentSlideIndex + 1}}/{{learningPathList?.length}}
      </div>
      <button class="button button--icon button--transparent" mat-icon-button [disabled]="isLastSlide | async" (click)="slickCarousel?.slickNext()">
        <lib-icon icon="arrow-right"></lib-icon>
      </button>
    </div>
  </div>
  <div class="learning-path-block__content row flex-column flex-lg-row">
    <div class="col-12 px-0 col-xl-8">
      <ngx-slick-carousel
        slickCarouselResize
        class="carousel" 
        #slickCarousel="slick-carousel"
        (init)="slickInit($event)"
        (afterChange)="afterChange($event)"
        [config]="carouselConfig">
        <div class="slide" ngxSlickItem *ngFor="let learningPath of learningPathList; index as i">
          <div class="learning-path-block__card" (click)="navigateToLearningPath(learningPath, $event)" (mousedown)="onMouseDown($event)">
            <div
              class="learning-path-block__card-image"
              [cropImage]="learningPath.mediaImage | preSignedUrl | async" 
              [crop]="learningPath.mediaImage?.crop">
              <div class="learning-path-block__card-deadline" *ngIf="learningPath.deadline" [class.overdue]="learningPath.overdue">
                <ng-container *ngIf="!learningPath.overdue; else overdueTextTmp">
                  {{'LEARNING_PATH.gain_points_deadline' | translate:{date: learningPath.deadline | customDate} }}
                </ng-container>
                <ng-template #overdueTextTmp>
                  {{'LEARNING_PATH.past_deadline' | translate:{date: learningPath.deadline | customDate} }}
                </ng-template>
              </div>
            </div>
            <div class="learning-path-block__card-content">
              <div
                class="learning-path-block__card-content-image"
                [cropImage]="learningPath.mediaImage | preSignedUrl | async" 
                [crop]="learningPath.mediaImage?.crop"
                imageWidth="200">
              </div>
              <div class="learning-path-block__card-details">
                <div class="learning-path-block__card-details-header">
                  <div class="learning-path-block__card-details-header-title">{{'LEARNING_PATH.learning_path' | translate}}</div>
                </div>
                <div class="learning-path-block__card-details-title">{{learningPath.title}}</div>
                <div *ngIf="learningPath.progress || learningPath.leaderboardPoints || learningPath.badge || learningPath.customCertificate || learningPath.time" class="learning-path-block__card-details-footer">
                  <app-progress *ngIf="learningPath.progress" progressColor="primary" [progress]="learningPath.progress"></app-progress>
                  <div *ngIf="learningPath.time" class="content-card__body--label-item">
                    <lib-icon icon="clock" [size]="16"></lib-icon> {{learningPath.time | minutesToTime:'short' }}
                  </div>
                  <div *ngIf="learningPath.leaderboardPoints" [matTooltip]="'COMMON.content_complete_points' | translate:{points: learningPath.leaderboardPoints}" class="content-card__body--label-item">
                    <lib-icon icon="currency-eth" [size]="16"></lib-icon> {{learningPath.leaderboardPoints}} {{'LEADERBOARD.pts' | translate | lowercase}}
                  </div>
                  <div *ngIf="learningPath.badge" [matTooltip]="'COMMON.content_complete_achievements' | translate:{count: 1, content: learningPath.badge.title}" class="content-card__body--label-item">
                    <lib-icon icon="medal" [size]="16"></lib-icon> {{'COMMON.achievement' | translate}}
                  </div>
                  <div *ngIf="learningPath.customCertificate" [matTooltip]="'COMMON.content_complete_certificate' | translate:{ certificate: learningPath.customCertificate.title }" class="content-card__body--label-item">
                    <lib-icon icon="certificate" [size]="16"></lib-icon> {{'COMMON.certificate' | translate}}
                  </div>
                </div>
              </div>
              <div class="learning-path-block__card-button">
                <button mat-flat-button class="button button--primary" (click)="navigateToLearningPath(learningPath, $event)">
                  <lib-icon icon="play"></lib-icon>
                  {{(learningPath.progress === 0 ? 'LEARNING_PATH.start_path' : 'LEARNING_PATH.continue_path') | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="col-12 px-0 col-xl-4">
      <div class="learning-path-block__counter-list">
        <div class="learning-path-block__counter-item active-learning-paths">
          <div class="learning-path-block__counter-item__icon">
            <lib-icon icon="path" [duotone]="true" size="48"></lib-icon>
          </div>
          <div class="learning-path-block__counter-item__body">
            <div class="learning-path-block__counter-item__title">{{'LEARNING_PATH.n_active_learning_paths' | translate: {value: activeLearningPaths} }}</div>
            <div *ngIf="activeLearningPaths" class="learning-path-block__counter-item__subtitle">{{'COMMON.keep_learning' | translate }}</div>
          </div>
        </div>
        <div class="learning-path-block__counter-item completed-learning-paths">
          <div class="learning-path-block__counter-item__icon">
            <lib-icon icon="flag-banner" [duotone]="true" size="48"></lib-icon>
          </div>
          <div class="learning-path-block__counter-item__body">
            <div class="learning-path-block__counter-item__title">{{'LEARNING_PATH.n_finished_learning_paths' | translate: {value: completedLearningPaths} }}</div>
            <div *ngIf="completedLearningPaths" class="learning-path-block__counter-item__subtitle">{{'LESSON_FEEDBACK.well_done' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #nextLessonLoadingTmp>
  <mat-spinner diameter="40"></mat-spinner>
</ng-template>
