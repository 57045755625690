import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SlideFlipcardsDTO } from '../../../common/models';

@Component({
  selector: 'lib-flipcard-audio',
  templateUrl: './flipcard-audio.component.html',
  styleUrls: ['./flipcard-audio.component.scss']
})
export class FlipcardAudioComponent implements OnDestroy {
  @Input() public set src(value: string) {
    if (!value) {
      return;
    }
    
    this.initAudio(value);
  }

  @Input() invert = false;

  public audio!: HTMLAudioElement;
  public audioVolume!: number;
  public audioPlaying = false;

  public initialized = false;

  public onVolumeChange(value: number) {
    this.audio.volume = value;
  }

  public resetAudio(): void {
    this.audio.currentTime = 0;
  }

  public toggleAudio(): void {
    if (this.audio.paused) {
      this.audioPlaying = true;
      this.audio.play();
      return;
    }

    this.audioPlaying = false;
    this.audio.pause();
  }

  public onAudioVolumeSliderClick(event: any): void {
    event.stopPropagation();
  }

  private initAudio(src: string): void {
    this.audio = new Audio(src);
    this.audio.onloadedmetadata = () => this.loadedmetadata();
    this.audio.onended = () => this.audioEnded();
    this.initialized = true;
  }

  private loadedmetadata(): void {
    this.audioVolume = this.audio.volume;
  }

  private audioEnded(): void {
    this.audioPlaying = false;
  }

  ngOnDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio.remove();
    }
  }

}
