import { OFFLINE_MEDIA_PREFIX } from '../constants';
import { Directive, ElementRef, Input } from '@angular/core';
import { TokenApiService } from '../services';
import { SharedLibraryService } from '../services/shared-library.service';
import { CloudImageService } from '../services/cloud-image.service';


@Directive({
  /* tslint:disable-next-line */
  selector: '[cropImage]'
})
export class CropImageDirective {
  @Input() public dynamicWidth = true;
  @Input() public dynamicWidthCoeff = 1;

  @Input() public set cropImage(value: string) {
    if (this._cropImage === value) {
      return;
    }

    this._cropImage = value;
    setTimeout(() => {
      this.initialize();
    });
  }

  public get cropImage() {
    return this._cropImage || '';
  }

  @Input() crop: any;
  @Input() imageWidth: any;
  @Input() clearBackgroundWhenEmpty = false;

  @Input() set additionalOptions(value: any) {
    if (!value) {
      return;
    }

    this._additionalOptions = { ...value, width: value?.w || value?.width, height: value?.h || value.height, w: null, h: null };
    Object.keys(this._additionalOptions).forEach((key) => this._additionalOptions[key] == null && delete this._additionalOptions[key]);
  };

  public get additionalOptions() {
    return this._additionalOptions;
  }

  @Input() disabled = false;
  @Input() offlineImage = false;
  @Input() private = false;
  @Input() imageId?: string; // requried if private = true
  @Input() setContainerRatioAndSize = false;
  
  protected targetElement: HTMLElement;

  private _cropImage!: string | undefined;

  private readonly imageWidthRoundValue = 2;
  private readonly minWidth = 300;
  private _additionalOptions: { [key: string]: any } = {};

  private resizeObs: ResizeObserver;

  constructor(
    protected sharedLibraryService: SharedLibraryService,
    protected el: ElementRef,
    protected tokenApiService: TokenApiService,
    protected cloudImageService: CloudImageService
  ) { }

  async initialize(): Promise<void> {
    const targetEl = this.getTargetElement();
  
    if (!this.cropImage) {
      if (this.clearBackgroundWhenEmpty) {
        targetEl.style.backgroundImage = '';
      }

      return;
    }

    //Handles images stored in device's filesystem
    const offlineImage = this.isOffline(this.cropImage);
    if (offlineImage) {


      if (targetEl instanceof HTMLImageElement) {
        targetEl.src = offlineImage;
        return;
      }

      targetEl.style.backgroundImage = `url('${offlineImage}')`;
      return;
    }

    const base64regex = /^(?:[data]{4}:(text|image|application)\/[a-z]*)/;
    const isBase64 = base64regex.test(this.cropImage);
    if (isBase64) {
      targetEl.style.backgroundImage = `url('${this.cropImage}')`;

      return;
    }

    // fix when url is missing protocol
    if (this.cropImage.indexOf('//') === 0) {
      this.cropImage = `https:${this.cropImage}`;
    }

    if (this.disabled) {
      targetEl.style.backgroundImage = `url('${this.cropImage}')`;
      return;
    }

    let imageWidth = this.imageWidth;

    if (this.dynamicWidth && targetEl && !imageWidth) {
      const round = Math.pow(10, this.imageWidthRoundValue);
      const width = Math.ceil(this.elementOffsetWidth(targetEl?.parentElement) / round) * round;
      const coeff = this.dynamicWidthCoeff || 1;

      imageWidth = Math.max(width, this.minWidth) * coeff;
    }

    // Cloudimage doesn't directly support an opacity parameter as part of its image transformation 
    if (this.additionalOptions.opacity) {
      targetEl.style.opacity = this.additionalOptions.opacity;
      delete this.additionalOptions.opacity;
    }

    this.cloudImageService.generateCloudImageResourceUrl(this.cropImage, imageWidth, this.additionalOptions, this.crop, this.private, this.imageId)
      .subscribe(async result => {
        if (targetEl instanceof HTMLImageElement) {
          targetEl.src = result;
          return;
        }
    
        if (this.setContainerRatioAndSize) {
          await this.setElementSizeBasedOnImage(result, targetEl);
        }
    
        targetEl.style.backgroundImage = `url('${result}')`;  
      }); 
  }

  private getFirstParentWithNotEmptyWidth(element: HTMLElement): HTMLElement {
    if (!element) {
      return null;
    }

    if (element.offsetWidth) {
      return element;
    }

    return this.getFirstParentWithNotEmptyWidth(element.parentElement);
  }

  private elementOffsetWidth(element: HTMLElement): number {
    if (!element) {
      return 0;
    }

    return element.offsetWidth || this.elementOffsetWidth(element.parentElement);
  }

  private setElementSizeBasedOnImage(src: string, element: HTMLElement): Promise<void> {
    element.style.minWidth = null;
    const parentElement = this.getFirstParentWithNotEmptyWidth(element);

    return new Promise(resolve => {
      const constainer = parentElement?.offsetWidth;

      const image = new Image();

      image.addEventListener('load', () => {
        this.subscribeOnWidthChange(element, image, parentElement);

        const { width:originalWidth, height:originalHeight } = image;
        const ratio = constainer ? constainer / originalWidth : 1;
        const [width, height] = [originalWidth * ratio, originalHeight * ratio];

        if (!element.offsetWidth) {
          element.style.minWidth = `${Math.min(originalWidth, width)}px`
        }

        element.style.paddingTop = `${100 * height / width}%`;
        element.style.maxWidth = `${width}px`;
        resolve();
      });

      image.src = src;
    });
  }

  protected getTargetElement(): HTMLElement {
    return this.targetElement || this.el.nativeElement;
  }

  private subscribeOnWidthChange(nativeElement: HTMLElement, image: HTMLImageElement, parentElement: HTMLElement): void {
    if (this.resizeObs) {
      this.resizeObs.disconnect();
    }

    this.resizeObs = new ResizeObserver(() => {
      const constainer = parentElement.offsetWidth;

      const { width:originalWidth, height:originalHeight } = image;
      const ratio = constainer / originalWidth;
      const [width, height] = [originalWidth * ratio, originalHeight * ratio];

      nativeElement.style.minWidth = `${Math.min(originalWidth, width)}px`;
      nativeElement.style.paddingTop = `${100 * height / width}%`;
      nativeElement.style.maxWidth = `${width}px`;
    });


    this.resizeObs.observe(parentElement);
  }

/**
 * The function checks if a given URL starts with a specific prefix and returns the URL without the
 * prefix if it does, otherwise it returns false.
 * @param {string} url - The `url` parameter is a string that represents a URL.
 * @returns either a string or false.
 */
  private isOffline(url: string): string | false {
    const protocolToRemove = OFFLINE_MEDIA_PREFIX;

    if (url.startsWith(protocolToRemove)) {
      return url.slice(protocolToRemove.length);
    }

    return false;
  }
}
