<div class="content-highlight block-container" *ngIf="items && items.length" [style.color]="block.textColor">
  <div *ngIf="showCarouselBtns || title" class="content-highlight__header">
    <h4 class="content-highlight__title" [rtl]="block.langcode">{{title}}</h4>
    <div *ngIf="showCarouselBtns" class="content-highlight__arrows" [style.color]="block.textColor">
      <button class="button button--icon button--transparent" mat-icon-button [disabled]="isFirstSlide | async" (click)="slickCarousel?.slickPrev()">
        <lib-icon icon="arrow-left"></lib-icon>
      </button>
      <button class="button button--icon button--transparent" mat-icon-button [disabled]="isLastSlide | async" (click)="slickCarousel?.slickNext()">
        <lib-icon icon="arrow-right"></lib-icon>
      </button>
    </div>
  </div>

  <div class="col-12 px-0 w-100" [ngClass]="block.mobileCardStyle">
    <ng-container *ngIf="block.sliderEnabled; else listView">
      <ngx-slick-carousel
        slickCarouselResize
        class="content-highlight__carousel" 
        [config]="carouselConfig" 
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        #slickCarousel>
        <div ngxSlickItem *ngFor="let item of items" class="content-highlight__slide">
          <ng-container *ngTemplateOutlet="slideTmp; context: { item: item }"></ng-container>
        </div>
      </ngx-slick-carousel>
    </ng-container>
  </div>
</div>

<ng-template #listView>
  <div class="content-highlight__list-view">
    <div class="content-highlight__row cards-base-grid">
      <div *ngFor="let item of items" class="content-highlight__slide">
        <ng-container *ngTemplateOutlet="slideTmp; context: { item: item }"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template let-item="item" #slideTmp>
  <lib-small-card
    *ngIf="block.mobileCardStyle === mobileCardStyleTypes.SMALL_CARD"
    [data]="item"
    (cardClick)="navigate($event, item)"
    (mousedown)="onMouseDown($event)">
  </lib-small-card>
  <ng-container [ngSwitch]="item.bundle">
    <lib-class-card
      *ngSwitchCase="bundleTypes.CLASS"
      [data]="item"
      [orientation]="imageOrientation"
      (cardClick)="navigate($event, item)"
      (mousedown)="onMouseDown($event)">
    </lib-class-card>
    <lib-course-card
      *ngSwitchCase="bundleTypes.COURSE"
      [data]="item"
      [paymentEnabled]="isPaymentEnabled"
      (cardClick)="navigate($event, item)"
      (buttonClick)="navigate($event, item)"
      (detailsClick)="openDetails(item)"
      (mousedown)="onMouseDown($event)">
    </lib-course-card>
    <lib-course-card
      *ngSwitchCase="bundleTypes.MODULE"
      [data]="item"
      [showDetailsButton]="false"
      (cardClick)="navigate($event, item)"
      (buttonClick)="navigate($event, item)"
      (mousedown)="onMouseDown($event)">
    </lib-course-card>
    <lib-lesson-card
      *ngSwitchDefault
      [data]="item"
      (cardClick)="navigate($event, item)"
      (mousedown)="onMouseDown($event)">
    </lib-lesson-card>
  </ng-container>
</ng-template>
