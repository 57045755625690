<ng-scrollbar *ngIf="item[side === 'front' ? 'frontText' : 'backText']" [autoHeightDisabled]="false" class="flex-grow-1" thumbClass="custom-scrollbars" visibility="hover">
  <div class="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
    <div class="content ck-text" [innerHTML]="item[side === 'front' ? 'frontText' : 'backText'] | safeHtml" [rtl]="slide.langcode" [padding]="slide.spacing"></div>
  </div>
</ng-scrollbar>

<ng-container *ngIf="item[side === 'front' ? 'frontVideo' : 'backVideo'] || item[side === 'front' ? 'frontEmbedVideo' : 'backEmbedVideo']">
  <lib-video
    #videoEl
    [style.height.px]="element.nativeElement.offsetHeight || 300"
    class="flip-card__video"
    [embedVideo]="item[side === 'front' ? 'frontEmbedVideo' : 'backEmbedVideo']"
    [src]="item[side === 'front' ? 'frontVideo' : 'backVideo'] | videoPresign | async"
    [showThumbnailsForEmbed]="true"
    (started)="onMediaStart()">
  </lib-video>
  <div class="start-media-overlay" *ngIf="!mediaStarted" (click)="videoEl.startVideo()">
    <mat-icon>play_arrow</mat-icon> Play video
  </div>
</ng-container>

<ng-container *ngIf="item[side === 'front' ? 'frontAudio' : 'backAudio'] as audioFile">
  <mat-icon *ngIf="!item[side === 'front' ? 'frontMediaBackground' : 'backMediaBackground']" class="microphone-icon" svgIcon="lms-microphone" [class.white]="isDarkBackground"></mat-icon>
  <lib-flipcard-audio 
    [src]="audioFile | preSignedUrl : entityTypeId.PARAGRAPH : bundleType.FLIPCARD: (side === 'front' ? fieldName.AWS_FILE : fieldName.AWS_FILE_BACK) | async" 
    [invert]="isRtl">
  </lib-flipcard-audio>
</ng-container>

<div class="buttons-wrapper" (click)="stopPropagation($event)">
  <div class="icon-button" (click)="emitFlip()">
    <div class="icon-button__icon">
      <lib-icon icon="arrows-counter-clockwise"></lib-icon>
    </div>
  </div>
  <ng-container *ngIf="canEdit">
    <div class="icon-button" (click)="emitOrientation()">
      <div class="icon-button__icon">
        <mat-icon class="orientation-icon" [svgIcon]="'lms-card-' + (item.orientation === 'portrait' ? 'portrait' : 'landscape')"></mat-icon>
      </div>
    </div>
    <div class="icon-button" (click)="emitEdit()">
      <div class="icon-button__icon">
        <mat-icon class="edit-icon" svgIcon="lms-edit"></mat-icon>
      </div>
    </div>
    <div class="icon-button" (click)="emitRemove()">
      <div class="icon-button__icon">
        <mat-icon class="bin-icon" svgIcon="lms-bin"></mat-icon>
      </div>
    </div>
    <ng-content select="[drag-handle]"></ng-content>
  </ng-container>
</div>