import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, timer } from 'rxjs';
import { catchError, debounce, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { PreSignRequestData } from '../models';
import { MediaPresignApiService } from '../services';

@Injectable()
export class PreSignedUrlInterceptor implements HttpInterceptor {
  private readonly PRE_SIGN_REQUEST_URL = 'api/get-pre-signed-url'
  private readonly PRE_SIGN_REQUEST_COOKIES = 'api/get-pre-signed-cookies'

  private readonly REQUEST_DELAY_MS = 100;
  private readonly MAX_REQUESTS_COUNT = 20;

  private multipleRequestSubject = new Subject<{ url: string, key: string }[]>();

  private requestSubject = new Subject<HttpRequest<PreSignRequestData>>();
  private pendingRequests: HttpRequest<PreSignRequestData>[] = [];

  constructor(private readonly mediaPresignApiService: MediaPresignApiService) {
    this.init();
  }

  public intercept(req: HttpRequest<PreSignRequestData>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isPresignRequest(req)) {
      return next.handle(req);
    }

    this.requestSubject.next(req);
  
    return this.multipleRequestSubject
      .pipe(
        map(data => data.find(item => item.key === req.body.key)),
        filter(data => !!data),
        take(1),
        map(data => new HttpResponse({ status: 200, body: { url: data.url } }))
      );
  }

  private isPresignRequest(req: HttpRequest<PreSignRequestData>): boolean {
    return req.url.indexOf(this.PRE_SIGN_REQUEST_URL) !== -1 || req.url.indexOf(this.PRE_SIGN_REQUEST_COOKIES) !== -1;
  }

  private sendMultiplePresignRequests(): Observable<{ url: string, key: string }[]> {
    const requests = this.pendingRequests ? [...this.pendingRequests] : [];

    if (!requests || requests.length === 0) {
      return of([]);
    }

    this.pendingRequests = [];
    const data = requests.map(request => request.body);
  
    return this.mediaPresignApiService.getPreSignedUrlMulti(data)
      .pipe(
        map(urls => urls.map((url, index) => ({ url, key: data[index]?.key }))),
        catchError(() => {
          return of([]);
        }),
      )
  }

  private init(): void {
    this.requestSubject
      .pipe(
        tap((request: HttpRequest<PreSignRequestData>) => {
          this.pendingRequests.unshift(request);
        }),
        debounce(() => {
          if (this.pendingRequests?.length >= this.MAX_REQUESTS_COUNT) {
            return of(null);
          }

          return timer(this.REQUEST_DELAY_MS)
        }),
        mergeMap(() => this.sendMultiplePresignRequests()),
        tap(data => this.multipleRequestSubject.next(data))
      )
      .subscribe();
  }

}