import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProfileService } from 'library-explorer';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ClassDTO } from 'src/app/model/classDTO.model';
import { CourseDTO } from 'src/app/model/courseDTO.model';
import { PromotionBlock } from 'src/app/model/promotion-block';
import { ClassService } from 'src/app/services/api/class.service';
import { CourseService } from 'src/app/services/api/course.service';

import { NavigateService } from 'src/app/services/navigate.service';

@Component({
  selector: 'app-promotion-block',
  templateUrl: './promotion-block.component.html',
  styleUrls: ['./promotion-block.component.scss']
})
export class PromotionBlockComponent implements OnInit, OnDestroy {
  @Input() public block: PromotionBlock;

  public sliderCourses: CourseDTO[] = [];
  public selectedItemId = '';
  public userName: string;

  public sliderCoursesControl: FormControl;
  public classList: ClassDTO[] = [];
  public showPromotionVideo = false;
  public hasLeftColumn = false;

  private unsubscribe: Subject<void> = new Subject();
  public courseCarouselConfig: any;

  constructor(
    private readonly renderer: Renderer2,
    private readonly courseService: CourseService,
    private readonly classService: ClassService,
    private readonly profileService: ProfileService,
    private readonly navigateService: NavigateService
  ) { }

  ngOnInit() {
    this.userName = this.profileService.getCurrentProfileValue()?.name;
    this.hasLeftColumn = !!(this.block.title || this.block.mediaVideo?.uri || this.block.link?.uri || this.block.embedVideo);
    this.createControl();
    this.getClasses();

    this.courseCarouselConfig = {
      lazyLoad: 'ondemand',
      slidesToShow: this.hasLeftColumn ? 3 : 4,
      infinite: false,
      touchMove: true,
      swipeToSlide: true,
      variableWidth: false,
      arrows: false,
      autoplay: false,
      centerMode: false,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1661,
          settings: {
            slidesToShow: this.hasLeftColumn ? 2 : 3
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            infinite: true,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public playPromotionVideo() {
    const promotionLink = this.block.link && this.block.link.uri;
    if (promotionLink) {
      this.navigateService.navigate(promotionLink);
    } else {
      this.showPromotionVideo = true;
      this.renderer.setStyle(document.body, 'overflow', 'hidden');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public closeVideoOverlay(): void {
    this.showPromotionVideo = false;
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  private createControl() {
    this.sliderCoursesControl = new FormControl('');
    this.sliderCoursesControl.valueChanges
      .pipe(
        switchMap((id: string) => this.getCourses(id)),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private getCourses(id: string = null): Observable<unknown> {
    return this.courseService.getAllCourses(id)
      .pipe(
        tap((courses: CourseDTO[]) => this.sliderCourses = courses),
        takeUntil(this.unsubscribe)
      );
  }

  private getClasses() {
    const params = new HttpParams()
      .set('sort', 'weight:ASC,progress');

    this.classService.getAllClasses(params)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((classes: ClassDTO[]) => {
        this.classList = classes;
        const activeClassId = classes.length ? classes[0].id : null;
        this.sliderCoursesControl.patchValue(activeClassId);
      });
  }
}
