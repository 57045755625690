import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BundleType } from 'library-explorer';
import { QuickSearchInputComponent } from '../quick-search-input/quick-search-input.component';


@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent implements OnInit, OnDestroy {
  @ViewChild(QuickSearchInputComponent) public quickSearchInput: QuickSearchInputComponent;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<SearchDialogComponent>) { }

  ngOnInit(): void {
    this.addRouteSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public navigateToSearch(bundle: BundleType = null): void {
    this.router.navigate(['/search'], { queryParams: {
      search: this.quickSearchInput?.searchControl?.value || null, bundle
    }});
  }

  private addRouteSubscriptions(): void {
    this.router.events
     .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe),
     )
     .subscribe(() => {
       this.dialogRef.close();
     });
  }

}
