<section class="discover-content" *ngIf="block?.contents?.length" [style.color]="block.textColor">
  <div class="content block-container">
    <ng-container *ngIf="block.title">
      <h1 [innerHtml]="block.title | safeHtml"></h1>
    </ng-container>
    
    <ng-container *ngIf="block.sliderEnabled; else listView">
      <ngx-slick-carousel
        slickCarouselResize
        class="carousel" 
        #slickModal="slick-carousel"
        (init)="slickInit($event)"
        (breakpoint)="breakpoint($event)"
        (afterChange)="afterChange($event)"
        [config]="carouselConfig">
        <div ngxSlickItem *ngFor="let slide of block.contents" class="slide">
          <ng-container *ngTemplateOutlet="slideTmp; context: { slide: slide }"></ng-container>
        </div>
      </ngx-slick-carousel>
      <div class="d-none d-lg-flex carousel-buttons-wrapper" *ngIf="showCarouselBtns">
        <button color="primary" mat-fab class="carousel-button left" [style.color]="block.textColor" [style.border-color]="block.textColor" [class.disabled]="isFirstSlide | async" (click)="slickModal.slickPrev()">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <button color="primary" mat-fab class="carousel-button right" [style.color]="block.textColor" [style.border-color]="block.textColor" [class.disabled]="isLastSlide | async" (click)="slickModal.slickNext()">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</section>

<ng-template #listView>
  <div class="discover-content__list-view" [class.justify-content-center]="block.contents?.length < 4">
    <div *ngFor="let slide of block.contents" class="col-12 col-sm-6 col-md-4 col-xl-3 slide py-3">
      <ng-container *ngTemplateOutlet="slideTmp; context: { slide: slide }"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template let-slide="slide" #slideTmp>
  <div (mousedown)="onMouseDown($event)" (click)="navigate($event, slide)" class="card-item" [class.inactive]="!slide.active">
    <div class="image">
      <img 
        *ngIf="slide.mediaImage; else placeholder" 
        [cropImage]="slide.mediaImage | preSignedUrl | async" 
        [crop]="slide?.mediaImage?.crop" 
        imageWidth="400" 
        [attr.alt]="slide.mediaImage?.alt" >
      <ng-template #placeholder>
        <div class="image-placeholder"></div>
      </ng-template>
      <app-card-label 
        class="price-wrapper"
        *ngIf="slide.payable && paymentEnabled"
        [show]="true"
        [cssClass]="slide.isPaid && 'paid-label'"
        [label]="slide.isPaid ? ('COMMON.purchased' | translate) : (slide.price | currency:(slide.currency | uppercase):'symbol')">
      </app-card-label>

    </div>
    <div class="description">
      <h2 [innerHtml]="slide.title | safeHtml" class="mb-0"></h2>
    </div>
  </div>
</ng-template>
