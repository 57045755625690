import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponse } from 'src/app/model/data-response.model';
import { SearchItemDTO } from 'src/app/model/searchItemDTO.model';
import { HttpService, BundleType } from 'library-explorer';
import { SettingsService } from '../settings.service';
import { map } from 'rxjs/operators';
import { SectionStatus } from 'library-explorer';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(
    private readonly settingsService: SettingsService,
    private readonly httpService: HttpService) { }

  public getAvailableSearchBundles(): Observable<BundleType[]> {
    return this.settingsService.getSettings()
      .pipe(
        map(data => {
          const showLibraryMenu = data.header.showLibraryMenu !== false;
          const showWebinarMenu = data.webinars?.status === SectionStatus.VISIBLE;

          const bundles = [
            !data.class.hideClassLevel && BundleType.CLASS, 
            BundleType.COURSE, 
            !data.system.skip_module && BundleType.MODULE, 
            BundleType.LESSON, 
            BundleType.QUIZ, 
            showWebinarMenu && BundleType.WEBINAR, 
            showLibraryMenu && BundleType.LIBRARY
          ];

          return bundles.filter(item => item);
        })
      )
  }

  public searchBy(search: string, bundle: BundleType | BundleType[], offset = 0, limit = 20, displayContentList = false): Observable<DataResponse<SearchItemDTO>> {
    let params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString());


    if (displayContentList) {
      params = params.set('display', 'contents-list');
    }

    if (bundle) {
      const bundleParam = Array.isArray(bundle) ? bundle.join(',') : bundle;
      params = params.set('bundle', bundleParam);
    }

    if (search) {
      params = params.set('string', search);
    }

    return this.httpService.get<DataResponse<SearchItemDTO>>('search', params);
  }

  public groupSearch(search: string, bundle: BundleType | BundleType[], groupSize = 3, displayContentList = false): Observable<DataResponse<SearchItemDTO>> {
    let params = new HttpParams()
      .set('groupByTypes', groupSize);

    if (displayContentList) {
      params = params.set('display', 'contents-lists');
    }

    if (search) {
      params = params.set('string', search);
    }

    if (bundle) {
      const bundleParam = Array.isArray(bundle) ? bundle.join(',') : bundle;
      params = params.set('bundle', bundleParam);
    }

    return this.httpService.get<DataResponse<SearchItemDTO>>('search', params);
  }
}
