import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaintenanceInterceptor } from '../interceptors';

import { MaintenanceType } from '../models';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  public maintenanceModeEnabled = new BehaviorSubject<{ enabled: boolean, type?: MaintenanceType }>({ enabled: false });

  constructor(private httpService: HttpService) { }

  public enableMaintenanceMode(type = MaintenanceType.BASIC): void {
    const currentState = this.maintenanceModeEnabled.value;
    if (currentState && currentState.enabled) {
      return;
    }

    this.maintenanceModeEnabled.next({ enabled: true, type });
  }

  public disableMaintenanceMode(): void {
    this.maintenanceModeEnabled.next({ enabled: false });
  }


  public checkForMaintenanceMode(): Observable<void> {
    const params = new HttpParams()
      .set(MaintenanceInterceptor.SKIP_MAINTENANCE_CHECKING_PARAM, 'true');

    return this.httpService.head<void>('settings', params)
      .pipe(
        tap(() => {
          // Only drupal maintenance mode
          // reload APP to make sure settings / language locales are loaded
          location.reload();
        })
      )
  }
}
