<a 
  class="item" 
  *ngIf="!inCarousel; else inCarouselTemplate"
  [class.locked]="item.isLocked"
  (click)="navigate($event)">
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</a>

<ng-template #inCarouselTemplate>
  <div class="item rounded p-2" [class.locked]="item.isLocked">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <span class="item__content" [class.inactive]="!item.active">
    <div class="item-content-main">
      <ng-container *ngIf="!item.isLocked && paymentEnabled && item.payable && !item.isPaid">
        <app-card-label
          class="dashboard-item__price"
          [show]="true"
          [label]="(item.price | currency:(item.currency | uppercase):'symbol')"
          cssClass="small">
        </app-card-label>
      </ng-container>
      <span class="image-wrapper rounded mr-3" *ngIf="item.mediaImage">
        <span 
          class="image rounded" 
          [cropImage]="item.mediaImage | preSignedUrl | async" 
          [crop]="item.mediaImage?.crop"
          imageWidth="150"
          role="img"
          [attr.aria-label]="item.mediaImage?.description || item.coverImage?.description || ''">
        </span>
      </span>
      <div class="text-wrapper">
        <h4 class="ml-1 mb-0">{{item.title}}</h4>
        <div class="parent-course ml-1" *ngIf="item.parentCourseName">
          {{item.parentCourseName}}
        </div>
      </div>
    </div>
  </span>
  <div class="item__locked primary-background" *ngIf="item.isLocked">
    <mat-icon svgIcon="lms-lock"></mat-icon>
  </div>
  <app-progress
    *ngIf="item.time || progressShow || (chaptersLeft && item.amountNotFinishedSliders) || itemCountLabel || item.progress"
    [time]="item.time" 
    [progressShow]="progressShow" 
    [chaptersLeft]="chaptersLeft && item.amountNotFinishedSliders" 
    [itemCountLabel]="itemCountLabel" 
    [progress]="item.progress">
  </app-progress>
  <button mat-flat-button class="button button--secondary start-button" type="button" *ngIf="startButton">
    <span>{{'COMMON.start_'+item.bundle | translate}}</span>
  </button>
</ng-template>
