<div class="video" *ngIf="initialized | async" [class.hide-controls]="hideControls" #videoContainer>
  <ng-container *ngIf="src; else embeddedVideoTmp">
    <plyr 
      class="plyr-player-wrapper"
      [plyrDriver]="driver"
      (plyrInit)="plyrInit($event)"
      (plyrPlay)="videoStarted()"
      (playing)="startOrResume($event)"
      (pause)="pauseOrFinish($event)"
      (plyrEnded)="end()"
      (plyrTimeUpdate)="timeUpdate()"
      (plyrLoadedData)="loadedData()"
      (plyrCanPlay)="plyrCanPlay()"
      [plyrPoster]="poster?.uri"
      [plyrOptions]="options"
      [plyrCrossOrigin]="true"
      [plyrSources]="videoSources"
      (plyrEnded)="end()"
      [plyrTracks]="videoTracks">
    </plyr>

    <div class="video__warning-message" *ngIf="wargingMessage">
      <mat-icon class="video__warning-message-icon">error</mat-icon>
      <span class="video__warning-message-title">{{ wargingMessage | translate }}</span>
      <mat-icon class="video__warning-message-close" (click)="hideWarningMessage()">close</mat-icon>
    </div>

    <div class="interactive-video-overlay" [class.block-video-click]="isKeyFrameActive && !isAdmin" *ngIf="interactiveData">
      <div class="interactive-video-play-button-wrapper" *ngIf="plyr?.stopped">
        <div class="interactive-video-play-button secondary-background" (click)="startVideo()">
          Start interactive video
        </div>
      </div>
      <ng-container *ngIf="nextKeyFrame && isKeyFrameActive">
        <div
          *ngFor="let decision of nextKeyFrame.decisions"
          class="interactive-video-decision"
          (click)="keyFrameAction(decision)"
          [style.width.%]="decision.w / interactiveData.script.videoWidth * 100"
          [style.height.%]="decision.h / interactiveData.script.videoHeight * 100"
          [style.left.%]="decision.x / interactiveData.script.videoWidth * 100"
          [style.top.%]="decision.y / interactiveData.script.videoHeight * 100"
        ></div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #embeddedVideoTmp>
    <ng-container *ngIf="embed" [ngSwitch]="embed.provider">
      <ng-container *ngIf="showThumbnailsForEmbed && !userStartWatchingVideo">
        
        <ng-container *ngIf="!useCustomThumbnailForEmbed; else embedPoster">
          <ng-container *ngIf="embedThrumbnail | async as thumbnail">
            <img class="video__thumbnail" [src]="thumbnail" alt="" (click)="startVideo()">
          </ng-container>
        </ng-container>

        <ng-template #embedPoster>
          <ng-container *ngIf="poster">
            <div class="video__thumbnail" [mediaBackground]="{ mediaBackground: poster }" (click)="startVideo()">
              <mat-icon class="video__thumbnail-play">play_arrow</mat-icon>
            </div>
          </ng-container>
        </ng-template>
       
      </ng-container>
      
      <app-youtube-player (started)="videoStarted()" (watched)="end()" *ngSwitchCase="'youtube'" [videoId]="embed.id" [startTime]="embed.start"></app-youtube-player>
      <app-vimeo-player (started)="videoStarted()" (watched)="end()" *ngSwitchCase="'vimeo'" [videoId]="embed.id" [hash]="embed.hash"></app-vimeo-player>
    </ng-container>
  </ng-template>
</div>
