import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BundleType,
  EntityTypeId,
  FieldName,
  MediaPresignApiService,
  HttpService,
  MediaHelperService,
  ProviderType,
  SlideType,
  TokenApiService
} from 'library-explorer';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { EntityFinishedResponse } from 'src/app/model/enitiy-finished-response';
import { LayoutType } from 'src/app/model/enums/layout-type.enum';
import { QuestionDTO } from 'src/app/model/questionDTO.model';
import { SlideDTO } from '../../model/slideDTO.model';

@Injectable({
  providedIn: 'root'
})
export class SlidesApiService {

  constructor(
    private readonly mediaHelperService: MediaHelperService,
    private readonly tokenApiService: TokenApiService,
    private readonly mediaPresignApiService: MediaPresignApiService,
    private readonly httpService: HttpService,
  ) { }

  public slideStarted(id: string): Observable<void> {
    return this.httpService.post<void>('slides-started', { id });
  }

  public getSlidesByLesson(currentValue: string): Observable<SlideDTO[]> {
    const params = new HttpParams().set('handlePermissionDeniedResponses', 'true');
  
    return this.httpService.get<SlideDTO[]>('slides?parentLesson=' + currentValue, params);
  }

  public getSlideById(id: string): Observable<SlideDTO[]> {
    const params = new HttpParams().set('id', id);

    return this.httpService.get<SlideDTO[]>('slides', params);
  }

  public completeSlide(id: string, courseId: string): Observable<EntityFinishedResponse> {
    return this.httpService.post('slides-finish', { id, course_id: courseId });
  }

  public setVideoToWatchedOnSlide(slideId: string): Observable<any> {
    const data = {
      id: slideId,
      entityTypeId: 'paragraph',
      status: true
    };

    return this.httpService.post<any>('video-watched', data);
  }

  public loadPresignedUrlsForSlideMedia(chapter: SlideDTO & { columnsRatio?: number }, width = 0): Observable<SlideDTO | QuestionDTO> {
    if (!chapter) {
      return of(null);
    }

    if (chapter?.availableOffline) {
      return of(chapter);
    }

    const mediaObs = [];

    if (chapter.audio && chapter.audio.provider === ProviderType.AWS) {
      const audioObs = this.mediaPresignApiService.getPreSignedUrl(
        EntityTypeId.PARAGRAPH, chapter.bundle, FieldName.AWS_FILE, chapter.audio.key
        ).pipe(
          tap(signedUrl => Object.assign(chapter.audio, { uri: signedUrl }))
        );
      mediaObs.push(audioObs);
    }

    if (chapter.mediaImage && chapter.mediaImage.provider === ProviderType.AWS) {
      const imageObs = this.mediaPresignApiService.getPreSignedUrl(
        EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.MEDIA_IMAGE, chapter.mediaImage.key
      )
        .pipe(
          tap(signedUrl => Object.assign(chapter.mediaImage, { uri: signedUrl })),
        );
      mediaObs.push(imageObs);
    }

    if (chapter.mobileMediaImage && chapter.mobileMediaImage.provider === ProviderType.AWS) {
      const imageObs = this.mediaPresignApiService.getPreSignedUrl(
        EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.MEDIA_IMAGE, chapter.mobileMediaImage.key
      )
        .pipe(
          tap(signedUrl => Object.assign(chapter.mobileMediaImage, { uri: signedUrl }))
        );
      mediaObs.push(imageObs);
    }

    if (chapter.enableBackground) {
      if (chapter.mediaBackground && chapter.mediaBackground.provider === ProviderType.AWS) {

        const imageObs = this.mediaPresignApiService.getPreSignedUrl(
          EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.MEDIA_BACKGROUND, chapter.mediaBackground.key)
          .pipe(
            tap(signedUrl => Object.assign(chapter.mediaBackground, { uri: signedUrl })),
            mergeMap(() => {
              if (!width) {
                return of(null);
              }

              return this.mediaHelperService.preloadCloudImage(chapter.mediaBackground.uri, width, { func: 'bound' });
            })
          );

        mediaObs.push(imageObs);
      }
  
      if (chapter.mobileMediaBackground && chapter.mobileMediaBackground.provider === ProviderType.AWS) {
        const imageObs = this.mediaPresignApiService.getPreSignedUrl(
          EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.MEDIA_BACKGROUND, chapter.mobileMediaBackground.key)
          .pipe(
            tap(signedUrl => Object.assign(chapter.mobileMediaBackground, { uri: signedUrl }))
          );
        mediaObs.push(imageObs);
      }

      if (chapter.rightBackground?.mediaBackground && chapter.rightBackground?.mediaBackground.provider === ProviderType.AWS) {
        const imageObs = this.mediaPresignApiService.getPreSignedUrl(
          EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.FIELD_RIGHT_MEDIA, chapter.rightBackground.mediaBackground.key)
          .pipe(
            tap(signedUrl => Object.assign(chapter.rightBackground.mediaBackground, { uri: signedUrl })),
            mergeMap(() => {
              if (!width) {
                return of(null);
              }

              const ratio = chapter.columnsRatio || 1;
              const rightColumnWidth = width * (100 - 50 * ratio) / 100;
            
              return this.mediaHelperService.preloadCloudImage(chapter.rightBackground.mediaBackground.uri, rightColumnWidth, { func: 'bound' });
            })
          );
      
        mediaObs.push(imageObs);
      }
  
      if (chapter.rightBackground?.mobileMediaBackground && chapter.rightBackground?.mobileMediaBackground.provider === ProviderType.AWS) {
        const imageObs = this.mediaPresignApiService.getPreSignedUrl(
          EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.FIELD_RIGHT_MOBILE_MEDIA, chapter.rightBackground.mobileMediaBackground.key)
          .pipe(
            tap(signedUrl => Object.assign(chapter.rightBackground.mobileMediaBackground, { uri: signedUrl }))
          );
      
        mediaObs.push(imageObs);
      }
  
      if (chapter.leftBackground?.mediaBackground && chapter.leftBackground?.mediaBackground.provider === ProviderType.AWS) {
        const imageObs = this.mediaPresignApiService.getPreSignedUrl(
          EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.FIELD_LEFT_MEDIA, chapter.leftBackground.mediaBackground.key)
          .pipe(
            tap(signedUrl => Object.assign(chapter.leftBackground.mediaBackground, { uri: signedUrl })),
            mergeMap(() => {
              if (!width) {
                return of(null);
              }

              const ratio = chapter.columnsRatio || 1;
              const leftColumnWidth = width * ratio * 50 / 100;

              return this.mediaHelperService.preloadCloudImage(chapter.leftBackground.mediaBackground.uri, leftColumnWidth, { func: 'bound' });
            })
          );
      
        mediaObs.push(imageObs);
      }
  
      if (chapter.leftBackground?.mobileMediaBackground && chapter.leftBackground?.mobileMediaBackground.provider === ProviderType.AWS) {
        const imageObs = this.mediaPresignApiService.getPreSignedUrl(
          EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.FIELD_LEFT_MEDIA, chapter.leftBackground.mobileMediaBackground.key)
          .pipe(
            tap(signedUrl => Object.assign(chapter.leftBackground.mobileMediaBackground, { uri: signedUrl }))
          );
      
        mediaObs.push(imageObs);
      }
    }

    if (chapter.hotspots && chapter.hotspots.background.mediaImage
      && chapter.hotspots.background.mediaImage.provider === ProviderType.AWS) {
      const imageObs = this.mediaPresignApiService.getPreSignedUrl(
        EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.MEDIA_IMAGE, chapter.hotspots.background.mediaImage.key)
        .pipe(
          tap(signedUrl => Object.assign(chapter.hotspots.background.mediaImage, { uri: signedUrl }))
        );
      mediaObs.push(imageObs);
    }

    if (chapter.mediaVideo?.coverImage?.provider === ProviderType.AWS) {
      const imageObs = this.mediaPresignApiService.getPreSignedUrl(
        EntityTypeId.MEDIA, BundleType.VIDEO, FieldName.FIELD_COVER_IMAGE, chapter.mediaVideo?.coverImage?.key)
        .pipe(
          tap(signedUrl => Object.assign(chapter.mediaVideo.coverImage, { uri: signedUrl }))
        );

      mediaObs.push(imageObs);
    }

    if (chapter.buckets && chapter.buckets.length) {
      chapter.buckets.forEach(bucket => {
        const cards = bucket.cards.filter(card => card.mediaBackground && card.mediaBackground.provider === ProviderType.AWS) || [];
        cards.forEach(card => {
          const imageObs = this.mediaPresignApiService.getPreSignedUrl(
            EntityTypeId.PARAGRAPH, BundleType.CARD, FieldName.MEDIA_BACKGROUND, card.mediaBackground.key)
            .pipe(
              tap(signedUrl => Object.assign(card.mediaBackground, { uri: signedUrl }))
            );
          mediaObs.push(imageObs);
        });
      });
    }

    if (chapter.flipcards && chapter.flipcards.length) {

      chapter.flipcards.forEach(card => {
        if (card.frontMediaBackground && card.frontMediaBackground.provider === ProviderType.AWS) {
          const imageObs = this.mediaPresignApiService.getPreSignedUrl(
            EntityTypeId.PARAGRAPH, BundleType.FLIPCARD, FieldName.MEDIA_IMAGE, card.frontMediaBackground.key)
            .pipe(
              tap(signedUrl => Object.assign(card.frontMediaBackground, { uri: signedUrl }))
            );

          mediaObs.push(imageObs);
        }

        if (card.backMediaBackground && card.backMediaBackground.provider === ProviderType.AWS) {
          const imageObs = this.mediaPresignApiService.getPreSignedUrl(
            EntityTypeId.PARAGRAPH, BundleType.FLIPCARD, FieldName.MEDIA_BACKGROUND, card.backMediaBackground.key)
            .pipe(
              tap(signedUrl => Object.assign(card.backMediaBackground, { uri: signedUrl }))
            );

          mediaObs.push(imageObs);
        }
      });
    }

    if (chapter.accordion && chapter.accordion.length) {
      const list = chapter.accordion.filter(item => item.mediaImage && item.mediaImage.provider === ProviderType.AWS);
      list.forEach(acordion => {
        const imageObs = this.mediaPresignApiService.getPreSignedUrl(
          EntityTypeId.PARAGRAPH, BundleType.IMAGE, FieldName.MEDIA_IMAGE, acordion.mediaImage.key)
          .pipe(
            tap(signedUrl => Object.assign(acordion.mediaImage, { uri: signedUrl }))
          );
        mediaObs.push(imageObs);
      });
    }

    if (chapter.bundle === SlideType.SlideScormPackage && chapter.packagePath && chapter.packagePath.provider === ProviderType.AWS) {
      const scormCookiesObs = this.mediaPresignApiService.getPreSignedUrlCookies(
        EntityTypeId.PARAGRAPH, BundleType.SLIDE_SCORM, FieldName.AWS_FILE, chapter.packagePath.key)
        .pipe(
          tap(signedUrl => Object.assign(chapter.packagePath, { uri: signedUrl }))
        );
    
      mediaObs.push(scormCookiesObs);
    }

    if (chapter.bundle === SlideType.SlideBuilder) {
      const sections = chapter.items as any[];
      sections.forEach(item => {
        const sectionObs = this.loadPresignedUrlsForSlideMedia(item, width);
        mediaObs.push(sectionObs);

        switch (item.type) {
          case LayoutType.ONE_COLUMN:
            item.items = item.items || [];
            item.items.forEach(slide => {
              const widgetObs = this.loadPresignedUrlsForSlideMedia(slide, width);
              mediaObs.push(widgetObs);
            });
            break;
          case LayoutType.TWO_COLUMN:
            item.left_columns_items = item.left_columns_items || [];
            item.left_columns_items.forEach(slide => {
              const widgetObs = this.loadPresignedUrlsForSlideMedia(slide, width);
              mediaObs.push(widgetObs);
            });
            item.right_columns_items = item.right_columns_items || [];
            item.right_columns_items.forEach(slide => {
              const widgetObs = this.loadPresignedUrlsForSlideMedia(slide, width);
              mediaObs.push(widgetObs);
            });
            break;
        }
      });
    }

    const response = mediaObs.length ? forkJoin(mediaObs) : of(null);

    return response.pipe(
      catchError(() => {
       return of(null);
      }),
      map(() => chapter)
    );
  }
}
