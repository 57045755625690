<div class="d-block col-12 px-0 w-100 carousel-wrapper" [ngClass]="{'carousel__custom-arrow': type==='promotion-course'}"
     *ngIf="items && items.length">
  <ngx-slick-carousel
    slickCarouselResize
    class="carousel"
    [ngClass]="type"
    #slickModal="slick-carousel"
    [class.single-item]="type === CarouselType.COURSE"
    (init)="slickInit()"
    (afterChange)="afterChange($event)"
    [config]="config">
    <div ngxSlickItem *ngFor="let item of items" [class.px-3]="type === CarouselType.CLASS">
      <ng-container [ngSwitch]="type">
        <app-dashboard-item
          *ngSwitchCase="CarouselType.DASHBOARD_ITEM"
          [item]="item"
          [progressShow]="true"
          [startButton]="item.isNextRecommended"
          [paymentEnabled]="isPaymentEnabled"
          [inCarousel]="true"
          (mousedown)="onMouseDown($event)"
          (click)="navigate(item, $event)">
        </app-dashboard-item>

        <app-event-card  
          *ngSwitchCase="CarouselType.WEBINAR"
          [item]="item"
          [disabled]="true"
          class="event-card"
          (mousedown)="onMouseDown($event)" 
          (click)="navigate(item, $event)">
        </app-event-card>

        <lib-course-card
          *ngSwitchDefault
          [data]="item"
          [paymentEnabled]="isPaymentEnabled"
          [extended]="extendedCard"
          (cardClick)="navigate(item, $event)"
          (buttonClick)="navigate(item, $event)"
          (mousedown)="onMouseDown($event)"
          (detailsClick)="openDetails(item)">
        </lib-course-card>
      </ng-container>
    </div>
  </ngx-slick-carousel>

  <section class="custom-buttons-wrapper" *ngIf="type === CarouselType.PROMOTION_COURSE && showCarouselBtns">
    <button color="primary" mat-fab class="carousel__button left mr-3" (click)="slickModal.slickPrev()">
      <lib-icon icon="arrow-left"></lib-icon>
    </button>
    <button color="primary" mat-fab class="carousel__button right" (click)="slickModal.slickNext()">
      <lib-icon icon="arrow-right"></lib-icon>
    </button>
  </section>
</div>
