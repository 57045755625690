<div *ngIf="!hide" class="native-navigation-bar">
  <a class="native-navigation-bar__item" [routerLink]="['/dashboard']" routerLinkActive="active-link">
    <lib-icon icon="house" size="20"></lib-icon>
    {{ 'COMMON.home' | translate }}
  </a>
  <a *ngIf="searchEnabled" class="native-navigation-bar__item" openSearchDialog routerLinkActive="active-link">
    <lib-icon icon="magnifying-glass" size="20"></lib-icon>
    {{ 'MENU.search' | translate }}
  </a>
  <a class="native-navigation-bar__item" [routerLink]="['/classes']" [class.active-link]="catalogActive">
    <lib-icon icon="book-open-text" size="20"></lib-icon>
    {{ 'COMMON.catalog' | translate }}
  </a>
  <a *ngIf="chatEnabled" class="native-navigation-bar__item" [routerLink]="['/chat']" routerLinkActive="active-link">
    <lib-icon icon="chat-dots" size="20"></lib-icon>
    {{ 'COMMON.chat' | translate }}
  </a>
  <a class="native-navigation-bar__item" [routerLink]="['/profile']" routerLinkActive="active-link">
    <lib-icon icon="user" size="20"></lib-icon>
    {{ 'COMMON.profile' | translate }}
  </a>
</div>