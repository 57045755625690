import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EntityTypeId, LibraryItem, BundleType } from 'library-explorer';
import { GlossaryItem } from 'src/app/model/glossary-item';
import { LibraryResponseDto } from 'src/app/model/library-response-dto.model';
import { DownloadService } from 'src/app/services/api/download.service';
import { DocumentPreviewService } from 'src/app/shared/components/document-preview/services/document-preview.service';
import { UserActivityService } from '../user-activity.service';
import { FieldName, MediaPresignApiService, ProviderType } from 'library-explorer';
import { HttpService } from 'library-explorer';
import { DataResponse } from '@app/model/data-response.model';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  private glossaries: Observable<GlossaryItem[]>;

  constructor(
    private httpService: HttpService,
    private readonly mediaPresignApiService: MediaPresignApiService,
    private readonly documentPreviewService: DocumentPreviewService,
    private readonly downloadService: DownloadService,
    private readonly userActivityService: UserActivityService
  ) { }

  public clearCache() {
    this.glossaries = null;
  }

  public getLibrariesChildren(queryParams: any = {}, parentId: string = 'root'): Observable<LibraryResponseDto> {
    const params: HttpParams = new HttpParams({ fromObject: queryParams });

    return this.httpService.get<LibraryResponseDto>(`libraries/${parentId}/children`, params);
  }

  public getLibraries(queryParams: any = {}): Observable<LibraryResponseDto> {
    const params: HttpParams = new HttpParams({ fromObject: queryParams });

    return this.httpService.get<LibraryResponseDto>(`libraries`, params);
  }

  public getLibraryContentInfo(id: string): Observable<LibraryItem> {
    return this.httpService.get<LibraryItem>(`content-info/${id}`);
  }

  public getGlossaries(): Observable<GlossaryItem[]> {
    if (!this.glossaries) {
      this.glossaries = this.fetchGlosaries()
        .pipe(
          shareReplay({bufferSize: 1, refCount: true}),
          catchError(err => {
            this.glossaries = null;

            throw err;
          })
        );
    }

    return this.glossaries;
  }

  public fetchGlosaries(): Observable<GlossaryItem[]> {
    const params = new HttpParams()
      .set('vocabulary', 'glossary');

    return this.httpService.get<DataResponse<GlossaryItem>>('v2/taxonomies', params).pipe(map(res => res.items));
  }

  public openLibraryFile(item: LibraryItem): void {
    const presignRequest = item.file.provider === ProviderType.AWS ? this.getLibraryItemPresignedUrl(item) : of(null);
    
    presignRequest.subscribe((presignedUrl: string) => {
      this.markLibraryAsViewed(item.id).subscribe();
      this.documentPreviewService.openPreviewDialog(item.file, presignedUrl, null, item.id);
    });
  }

  public downloadLibraryFile(item: LibraryItem): void {
    this.getLibraryItemPresignedUrl(item).pipe(
      switchMap(url => this.downloadService.downloadFile(url, item.file.provider, item.file.filename)),
      tap(() => this.userActivityService.logUserDownloadFileActivity(item.id))
    ).subscribe();
  }

  public getLibraryItemPresignedUrl(item: LibraryItem): Observable<string> {
    if (item.file.provider !== ProviderType.AWS) {
      return of(item.file.uri);
    }

    return this.mediaPresignApiService.getPreSignedUrl(
      EntityTypeId.NODE,
      BundleType.LIBRARY,
      FieldName.AWS_FILE,
      item.file.key,
      item.file.preSignedToken
    );
  }

  public getLibraryById(id: string): Observable<LibraryItem> {
    const params: HttpParams = new HttpParams().set('id', id)

    return this.httpService.get<LibraryItem>('node', params);
  }

  public pinLibraryById(id: string): Observable<void> {
    return this.httpService.post<void>(`pin/library/${id}`, null);
  }

  public unpinLibraryById(id: string): Observable<void> {
    return this.httpService.post<void>(`unpin/library/${id}`, null);
  }

  public markLibraryAsViewed(id: string): Observable<void> {
    return this.httpService.post<void>(`mark-as-viewed/library/${id}`, null);
  }
}
