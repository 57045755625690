import { LoginIdentificationType, storageKeys } from 'library-explorer';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AndroidBiometryStrength, BiometricAuth, BiometryErrorType, BiometryType, CheckBiometryResult } from '@aparajita/capacitor-biometric-auth'
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SecureStorageService } from './secure-storage.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BiometricAuthModalComponent } from '@app/components/biometric-auth-modal/biometric-auth-modal.component';

@Injectable({
  providedIn: 'root'
})
export class BiometricAuthService {
  private isAvailable$ = new BehaviorSubject<boolean>(false);
  private isLoginEnabled$ = new BehaviorSubject<boolean>(false);
  private biometricData$ = new BehaviorSubject<boolean>(false);
  private biometricType$ = new BehaviorSubject<BiometryType>(BiometryType.none);

  public isBiometricLogin: boolean;

  constructor(private secureStorageService: SecureStorageService, private dialog: MatDialog, private storage: StorageMap) { }

  public isAvailable(): Observable<boolean> {
    return this.isAvailable$.asObservable();
  }

  public isAvailableValue(): boolean {
    return this.isAvailable$.value;
  }

  public isLoginEnabled(): Observable<boolean> {
    return this.isLoginEnabled$.asObservable();
  }

  public isLoginEnabledValue(): boolean {
    return this.isLoginEnabled$.value;
  }

  public isBiometricDataAvailable(): Observable<boolean> {
    return this.biometricData$.asObservable();
  }

  public isBiometricDataAvailableValue(): boolean {
    return this.biometricData$.value;
  }

  public biometricTypeValue(): BiometryType {
    return this.biometricType$.value;
  }

  public biometricTypeDescription(): string {
    const type = this.biometricType$.value;
    switch (type) {
      case BiometryType.touchId:
        return 'Touch ID';
      case BiometryType.faceId:
        return 'Face ID';
      case BiometryType.fingerprintAuthentication:
        return 'Fingerprint Authentication';
      case BiometryType.faceAuthentication:
        return 'Face Authentication';
      case BiometryType.irisAuthentication:
        return 'Iris Authentication';
      default:
        return 'Biometric Authentication';
    }
  }

  async initialize(): Promise<void> {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return;
    }

    this.checkBiometricsLoginEnabled();
    this.checkBiometricsData();

    const biometryInfo = await BiometricAuth.checkBiometry();
    this.updateBiometryInfo(biometryInfo);
    await BiometricAuth.addResumeListener(this.updateBiometryInfo.bind(this));
  }

  private checkBiometricsLoginEnabled() {
    this.storage.has(storageKeys.BIOMETRICS_ENABLED).subscribe(isEnabled => {
      this.isLoginEnabled$.next(isEnabled);
    });
  }

  private async checkBiometricsData() {
    this.storage.has(storageKeys.BIOMETRICS_DATA_AVAILABLE).subscribe(biometricDataExists => {
      this.biometricData$.next(biometricDataExists);
    });
  }

  private updateBiometryInfo(info: CheckBiometryResult): void {
    const isBiometryUnavailable =
      info.code === BiometryErrorType.biometryNotAvailable ||
      info.code === BiometryErrorType.biometryNotEnrolled;

    if (isBiometryUnavailable) {
      this.isAvailable$.next(false);
      this.isLoginEnabled$.next(false);
    } else {
      this.biometricType$.next(info.biometryType);
      this.isAvailable$.next(true);
    }
  }

  async authenticate(): Promise<boolean> {
    if (!this.isNativePlatformAndPluginAvailable() && !this.isAvailableValue()) {
      return false;
    }

    try {
      await BiometricAuth.authenticate({
        reason: 'Authenticate to continue',
        cancelTitle: 'Cancel',
        allowDeviceCredential: true,
        iosFallbackTitle: 'Use Passcode',
        androidTitle: 'Biometric Authentication',
        androidSubtitle: 'Sign in using your biometric credentials',
        androidConfirmationRequired: false,
        androidBiometryStrength: AndroidBiometryStrength.weak,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async storeBiometricsData(loginData: any): Promise<void> {
    const isAuthenticated = await this.authenticate();
    if (!isAuthenticated) return;

    await this.saveBiometricsData(loginData);
    this.enableBiometricsLogin();
  }

  async clearBiometricData(): Promise<void> {
    if (!this.isNativePlatformAndPluginAvailable() && !this.isLoginEnabledValue()) {
      return;
    }

    const wasBiometricDataRemoved = await this.removeBiometricsData();
    if (!wasBiometricDataRemoved) return;

    this.disableBiometricLogin();
  }

  async saveBiometricsData(loginData: any): Promise<void> {
    await this.secureStorageService.storeValue(storageKeys.BIOMETRICS_DATA, loginData);
    this.storage.set(storageKeys.BIOMETRICS_DATA_AVAILABLE, true).subscribe();
    this.biometricData$.next(true);
  }

  private async removeBiometricsData(): Promise<boolean> {
    const removedBiometricData = await this.secureStorageService.removeValue(storageKeys.BIOMETRICS_DATA);
    if (removedBiometricData) {
      this.storage.delete(storageKeys.BIOMETRICS_DATA_AVAILABLE).subscribe();
      this.biometricData$.next(false);
    }
    return removedBiometricData;
  }

  enableBiometricsLogin(): void {
    this.storage.set(storageKeys.BIOMETRICS_ENABLED, true).subscribe();
    this.isLoginEnabled$.next(true);
  }

  disableBiometricLogin(): void {
    this.storage.delete(storageKeys.BIOMETRICS_ENABLED).subscribe();
    this.isLoginEnabled$.next(false);
  }

  async initializeBiometricAuth(loginData: { username: string, password: string, idProperty?: string }) {
    const isBiometricUnavailable = !this.isNativePlatformAndPluginAvailable() || !this.biometricType$.value;

    if (isBiometricUnavailable) {
      return;
    }

    const dialogRef = this.dialog.open(BiometricAuthModalComponent, {
      panelClass: ['mobile-full-width-dialog'],
      minHeight: '500px',
      disableClose: true,
      data: {
        biometricType: this.biometricTypeValue(),
        biometricTypeDescription: this.biometricTypeDescription(),
        okBtnHandler: async () => {
          await this.storeBiometricsData(loginData);
          dialogRef.close(true);
        },
        cancelBtnHandler: () => dialogRef.close(true)
      }
    });
  }

  async handleBiometricData(data: { username: string, password: string }) {
    if (!this.isNativePlatformAndPluginAvailable()) {
      return;
    }

    const biometricLoginData = await this.biometricLoginData();

    if (!biometricLoginData) {
      return;
    }

    const { username: storedUsername, password: storedPassword } = biometricLoginData;
    const isPasswordChanged = storedPassword !== data.password;
    const isUsernameChanged = storedUsername !== data.username;

    if (isUsernameChanged && isPasswordChanged) {
      await this.clearBiometricData();
    }

    if (!isUsernameChanged && isPasswordChanged) {
      await this.saveBiometricsData(data);
    }
  }

  async biometricLoginData() {
    return await this.secureStorageService.getValue(storageKeys.BIOMETRICS_DATA) as { username: string, password: string, idProperty: LoginIdentificationType };
  }

  private isNativePlatformAndPluginAvailable(): boolean {
    return Capacitor.isNativePlatform() &&
      Capacitor.isPluginAvailable('BiometricAuthNative')
  }
}
