import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as Sentry from '@sentry/browser';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BaseWarningModalComponent } from '../components/base-warning-modal/base-warning-modal.component';

Sentry.init({
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Non-Error exception captured',
    'Wrong hostname, please contact site administrator',
    `Failed to execute 'transaction' on 'IDBDatabase`,
    'AbortError: The play() request was interrupted because video-only background media was paused to save power',
    'AbortError: The operation was aborted',
    'AbortError: The play() request was interrupted by a call to pause()',
    'Permissions check failed'
  ],
  dsn: environment.sentryUrl
});

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  public lastErrorId = null;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly dialog: MatDialog) {
    super();
  }

  public async handleError(error): Promise<void> {
    const isChunkError = this.checkForChunkError(error);

    if (isChunkError) {
      const isOnline = await this.checkForOffline();

      if (!isOnline) {
        super.handleError(error);
        return;
      }
    }

    if (environment.production && environment.sentryUrl) {
      this.sentryCaptureException(error);
    }

    super.handleError(error);
  }

  private sentryCaptureException(error): void {
    const err = error.originalError || error;
    const errorId = uuidv4();
    this.lastErrorId = errorId;

    const modifiedError = {
      ...err,
      message: `ID: ${errorId}, VERSION: ${environment.version}. MESSAGE: ${err.message}`,
    };

    if (err.user) {
      delete modifiedError.user.email;
      delete modifiedError.user.username;
    }

    Sentry.captureException(modifiedError);
  }

  private checkForOffline(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const hostname = window.location.hostname;
      this.httpClient.get(`${environment.hostLookupUrl}/${hostname}`)
        .pipe(
          catchError((err) => {
            resolve(false);
            return err;
          })
        )
        .subscribe(() => {
          resolve(true);
        });
    });
  }

  private checkForChunkError(error): boolean {
    const err = error.originalError || error;
    const chunkFailedMessage = /Loading chunk [\d|\w]+ failed/;

    const isChunkError = chunkFailedMessage.test(err.message);

    if (!isChunkError) {
      return false;
    }

    this.dialog.open(BaseWarningModalComponent, {
      data: {
        message: `WARNING.we_detected_your_device_not_able_download`,
        okBtnText: 'COMMON.reload',
        okBtnHandler: () => window.location.reload()
      }
    });

    return true;
  }
}
