import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@app/services/language.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-carousel-block',
  templateUrl: './carousel-block.component.html',
  styleUrls: ['./carousel-block.component.scss']
})
export class CarouselBlockComponent implements OnInit {
  @Input() public block: any;

  public carouselConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    infinity: true,
    arrows: false,
    autoplay: false,
    centerMode: false,
    adaptiveHeight: true,
    dots: false,
    rtl: false
  };

  public isFirstSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLastSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showCarouselBtns = false;

  constructor(
    private readonly languageService: LanguageService
  ) { }

  ngOnInit(): void {
    const isRtl = this.languageService.isRtl();
    if (isRtl) {
      this.carouselConfig.rtl = true;
    }
  }

  public slickInit(event): void {
    this.afterChange(event);
  }

  public afterChange(event): void {
    setTimeout(() => {
      const slidesToShow = event.slick.slickGetOption('slidesToShow');
      const slideCount = event.slick.slideCount;
      const currentSlide = event.slick.currentSlide;

      this.isFirstSlide.next(currentSlide === 0);
      this.isLastSlide.next(currentSlide + slidesToShow >= event.slick.slideCount);

      this.showCarouselBtns = slidesToShow < slideCount;
    });
  }

}
