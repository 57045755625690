<div class="achievement-card">
  <ng-container *ngIf="achievement">
    <div class="achievement-card__image">
      <img 
        *ngIf="achievement.mediaImage; else placeholderImageTemplate" 
        [cropImage]="achievement.mediaImage | preSignedUrl | async" 
        [additionalOptions]="{width: 150, height: 150, func: 'bound'}" 
        alt="achievement">
        <ng-template #placeholderImageTemplate>
          <img src="assets/images/achievement.png" alt="achievement">
        </ng-template>
    </div>
    <div class="achievement-card__title">
      {{achievement.title}}
    </div>
  </ng-container>
</div>