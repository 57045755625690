import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SettingsService } from '@app/services/settings.service';
import { SidebarSharedService } from '@app/services/sidebar-shared.service';
import { filter } from 'rxjs/operators';
import { ChatTypeEnum } from 'library-explorer';

@Component({
  selector: 'app-native-navigation-bar',
  templateUrl: './native-navigation-bar.component.html',
  styleUrls: ['./native-navigation-bar.component.scss']
})
export class NativeNavigationBarComponent implements OnInit {

  @Input() hide = false;

  public catalogActive = false;
  public chatEnabled = false;
  public searchEnabled = false;

  constructor(
    private readonly sidebarSharedService: SidebarSharedService,
    private readonly router: Router,
    private readonly settingsService: SettingsService,
    private readonly element: ElementRef
  ) { }

  ngOnInit(): void {
    this.settingsService.getSettings().subscribe(settings => {
      this.element.nativeElement.style.setProperty('--lms-native-navigation-bar-active-background', settings.colors?.nativeNavigationBar?.activeBackgroundColor || '');
      this.element.nativeElement.style.setProperty('--lms-native-navigation-bar-active-text', settings.colors?.nativeNavigationBar?.activeTextColor || '');
      this.chatEnabled = settings.chat?.chatType === ChatTypeEnum.INTERNAL_MESSAGING;
      this.searchEnabled = settings.header?.showSearchMenu;
    })

    this.catalogActive = this.sidebarSharedService.isCatalogMenuActive();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.catalogActive = this.sidebarSharedService.isCatalogMenuActive();
      });
  }

}
