import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SettingsResolver } from './resolvers/settings.resolver';
import { LanguageResolver } from './resolvers/languages.resolver';
import { AuthResolver } from './resolvers/auth.resolver';

const routes: Routes = [
  {
    path: 'no-instance',
    loadChildren: () => import('./components/no-instance-page/no-instance-page.module').then(m => m.NoInstancePageModule)
  },
  {
    path: '',
    resolve: {
      settings: SettingsResolver,
      languages: LanguageResolver,
      auth: AuthResolver
    },
    children: [
      {
        path: 'register', redirectTo: 'access/register',
      },
      {
        path: 'login', redirectTo: 'access/login'
      },
      {
        path: 'quick-login', redirectTo: 'access/quick-login'
      },
      {
        path: 'access',
        loadChildren: () => import('./components/auth-access-page/auth-access-page.module').then(m => m.AuthAccessPageModule)
      },
      {
        path: '',
        loadChildren: () => import('./components/full-layout/full-layout.module').then(m => m.FullLayoutModule)
      },
      {
        path: '**', redirectTo: 'access/login', pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
