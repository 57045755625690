import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VideoComponent } from '../../../common/components';
import { BundleType, EntityTypeId, FieldName, SlideFlipcardsDTO } from '../../../common/models';
import { FlipcardAudioComponent } from '../flipcard-audio/flipcard-audio.component';


@Component({
  selector: 'lib-flipcard-side',
  templateUrl: './flipcard-side.component.html',
  styleUrls: ['./flipcard-side.component.scss']
})
export class FlipCardSideComponent implements OnInit {
  @ViewChild(VideoComponent, { static: false }) videoComponent!: VideoComponent;
  @ViewChild(FlipcardAudioComponent, { static: false }) audioComponent!: FlipcardAudioComponent;

  @Input() side!: 'front'|'back';
  @Input() slide: any;
  @Input() item!: SlideFlipcardsDTO;
  @Input() canEdit = false;
  @Input() isRtl = false;
  @Output() flip: EventEmitter<void> = new EventEmitter();
  @Output() edit: EventEmitter<void> = new EventEmitter();
  @Output() remove: EventEmitter<void> = new EventEmitter();
  @Output() changeOrientation: EventEmitter<void> = new EventEmitter();

  @HostBinding('style.color')
  @Input() public textColor?: string;

  public mediaStarted = false;

  public isDarkBackground = false;

  public propertyKeys: any;

  public readonly entityTypeId: typeof EntityTypeId = EntityTypeId;
  public readonly bundleType: typeof BundleType = BundleType;
  public readonly fieldName: typeof FieldName = FieldName;

  constructor(
    public readonly element: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.propertyKeys = 'frontVideo';
  }

  public onMediaStart(): void {
    this.mediaStarted = true;

    // Required because of youtube embed
    this.changeDetectorRef.detectChanges();
  }

  public emitFlip(): void {
    this.flip.emit();
    this.stopMedia();
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public emitEdit(): void {
    this.edit.emit();
    this.stopMedia();
  }

  public emitRemove(): void {
    this.remove.emit();
    this.stopMedia();
  }

  public emitOrientation(): void {
    this.changeOrientation.emit();
    this.stopMedia();
  }

  public startMedia(): void {
    if (this.flipCardHasVideo()) {
      this.videoComponent.startVideo();
      return;
    }  
    
    if (this.flipCardHasAudio()) {
      this.audioComponent.audio.play();
      this.audioComponent.audioPlaying = true;
      return;
    }
  }

  private stopMedia(): void {
    if (this.flipCardHasVideo()) {
      this.videoComponent.pauseVideo();
      this.videoComponent.resetVideoTime();
      return;
    }  
    
    if (this.flipCardHasAudio()) {
      this.audioComponent.audio.pause();
      this.audioComponent.audioPlaying = false;
      this.audioComponent.resetAudio();
      return;
    }
  }

  private flipCardHasVideo(): boolean {
    // @ts-ignore
    return this.item[`${this.side}Video`] || this.item[`${this.side}EmbedVideo`];
  }

  private flipCardHasAudio(): boolean {
    // @ts-ignore
    return this.item[`${this.side}Audio`];
  }

}
