import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { QuestionDTO } from '@app/model/questionDTO.model';
import { SidebarTabType } from '@app/model/sidebar-tab-type.enum';
import { SlideDTO } from '@app/model/slideDTO.model';
import { LibraryItem, NodeModel } from 'library-explorer';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarSharedService {

  constructor(
    private readonly router: Router
  ) {}

  public navigationSidebarOpened = new BehaviorSubject(false);
  public secondarySidebarOpened = new BehaviorSubject(false);
  public tabsCount$ = new BehaviorSubject(0);
  public openTab$ = new Subject<SidebarTabType>();

  public currentEntity: BehaviorSubject<{
    data: NodeModel & { certificationEnabled?: boolean },
    children?: NodeModel[],
    chatEntity: NodeModel,
    lessonSlide?: SlideDTO | QuestionDTO
    hideOnPhoneWhenOnlyOnePresent?: boolean
  }> = new BehaviorSubject(null);

  public libraryCurrentEntity: BehaviorSubject<LibraryItem> = new BehaviorSubject<LibraryItem>(null);

  public mainSidebarTrigger(state = !this.navigationSidebarOpened.value): void {  
    this.navigationSidebarOpened.next(state);

    if (state) {
      this.secondarySidebarOpened.next(false);
    }
  }

  public secondarySidebarTrigger(state = !this.secondarySidebarOpened.value): void {
    this.secondarySidebarOpened.next(state);

    if (state) {
      this.navigationSidebarOpened.next(false);
    }
  }

  public openLibrarySidebar(item: LibraryItem): void {
    this.secondarySidebarTrigger(true);
    this.libraryCurrentEntity.next(item);
  }

  public openTabTrigger(type: SidebarTabType): void {
    this.openTab$.next(type);
  }

  public isCatalogMenuActive(): boolean {
    const catalogUrls = ['/classes', '/courses', '/modules', '/lessons', '/chapters'];
    return catalogUrls.some(url => this.router.url.indexOf(url) === 0);
  }
}
