import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BundleType, BUNDLE_TYPE_TEXT_MAP } from 'library-explorer';
import { NavigateFrom } from '@app/model/enums/navigate-from.enum';
import { LearningPathModel } from '@app/model/learning-path.model';
import { LearningPathService } from '@app/services/api/learning-path.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { LanguageService } from '@app/services/language.service';

@Component({
  selector: 'app-learning-path-block',
  templateUrl: './learning-path-block.component.html',
  styleUrls: ['./learning-path-block.component.scss']
})
export class LearningPathBlockComponent implements OnInit, OnDestroy {

  @Input() block: any;
  
  public learningPathList: LearningPathModel[] = [];
  public activeLearningPaths = 0;
  public completedLearningPaths = 0;
  public currentSlideIndex = 0;
  public mousePosition: any = {};

  public isFirstSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLastSlide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showCarouselBtns: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public BundleType = BundleType;
  public NavigateFrom = NavigateFrom;

  public carouselConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    infinite: false,
    arrows: false,
    autoplay: false,
    centerMode: false,
    adaptiveHeight: true,
    dots: false,
    rtl: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false
        }
      }
    ]
  };

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    @Inject(BUNDLE_TYPE_TEXT_MAP) public bundleTypeTextMap: any,
    private readonly learningPathService: LearningPathService,
    private readonly languageService: LanguageService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    const isRtl = this.languageService.isRtl();
    if (isRtl) {
      this.carouselConfig.rtl = true;
    }

    this.learningPathList = this.block.contents.map(this.learningPathService.processLearningPath);
    this.activeLearningPaths = this.learningPathList.reduce((prev, item) => prev += item.progress !== 100 ? 1 : 0, 0)
    this.completedLearningPaths = this.learningPathList.reduce((prev, item) => prev += item.progress === 100 ? 1 : 0, 0)
  }

  public afterChange(event): void {
    this.currentSlideIndex = event.slick.currentSlide;

    setTimeout(() => {
      const slidesToShow = event.slick.slickGetOption('slidesToShow');
      const slideCount = event.slick.slideCount;
      const currentSlide = event.slick.currentSlide;

      this.isFirstSlide.next(currentSlide === 0);
      this.isLastSlide.next(currentSlide + slidesToShow >= event.slick.slideCount);
      this.showCarouselBtns.next(slidesToShow < slideCount);
    });
  }

  public slickInit(event): void {
    this.afterChange(event);
  }

  public navigateToLearningPath(learningPath: LearningPathModel, event: MouseEvent): void {
    if (
      Math.abs(this.mousePosition.x - event.screenX) > 10
      || Math.abs(this.mousePosition.y - event.screenY) > 10
    ) {
      return;
    }

    this.router.navigate(['/learning-path', learningPath.uuid]);
  }

  public onMouseDown(event): void {
    this.mousePosition.x = event.screenX;
    this.mousePosition.y = event.screenY;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
