import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { filter, first, skip, take, tap } from 'rxjs/operators';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { MatomoInitializerService } from '@ngx-matomo/tracker';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { LegalCenterService } from './services/api/legal-center.service';
import { SettingsModel, CookieConsentService, MatomoModeEnum, CookieCategory, ColorTheme, HttpService, ProfileService, NetworkService, LocaleService, StorageService, STORAGE_KEYS, StorageKeys } from 'library-explorer';
import { DisqusChatService } from './services/disqus-chat.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { WarningModalComponent } from './shared/components/warning-modal/warning-modal.component';
import { sessionKeys } from './shared/constants/translation-helper.const';
import { HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { StorageMap } from '@ngx-pwa/local-storage';
import { CertificateService } from './components/certificate/services/certificate.service';
import { GamificationService } from './services/api/gamification.service';
import { AuthService } from './services/auth.service';
import { BranchService } from './services/branch.service';
import { environment } from '@env/environment';
import { TimeTrackingService } from './services/api/time-tracking.service';
import { LiveUpdateService } from './services/live-update.service';
import { PushNotificationService } from './services/push-notification.service';
import { SettingsService } from './services/settings.service';
import { NativeAppUpdateService } from './services/native-app-update.service';
import { BiometricAuthService } from './services/biometric-auth.service';
import { NavigateService } from './services/navigate.service';
import { Capacitor } from '@capacitor/core';
import { InAppBrowserService } from './services/in-app-browser.service';

declare let _paq: { push: (...args) => void };

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('notchOverlayEl') notchOverlayElRef: ElementRef;

  public showTranslationKeyToggle = false;

  private settings: SettingsModel;
  private subscriptions: Subject<unknown> = new Subject<unknown>();

  private analyticsScriptInitialized = false;

  constructor(
    private readonly storageService: StorageService,
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys,
    private readonly dialog: MatDialog,
    private readonly timeTrackingService: TimeTrackingService,
    private readonly cookieConsentService: CookieConsentService,
    private router: Router,
    private settingsService: SettingsService,
    private renderer: Renderer2,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private disqusChatService: DisqusChatService,
    private toastr: ToastrService,
    private networkService: NetworkService,
    private storage: StorageMap,
    private httpService: HttpService,
    private gamificationService: GamificationService,
    private certificateService: CertificateService,
    private zone: NgZone,
    private meta: Meta,
    private pushNotificationService: PushNotificationService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly legalCenterService: LegalCenterService,
    private readonly liveUpdateService: LiveUpdateService,
    private readonly nativeUpdateService: NativeAppUpdateService,
    private readonly bioAuthService: BiometricAuthService,
    private readonly navigateService: NavigateService,
    private readonly matomoInitializer: MatomoInitializerService,
    private readonly profileService: ProfileService,
    private readonly authService: AuthService,
    private readonly branchService: BranchService,
    private readonly localeService: LocaleService,
    private readonly inAppBrowserService: InAppBrowserService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.addAppOpenListener();
  }

  ngOnInit() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      this.renderer.setStyle(this.notchOverlayElRef.nativeElement, 'height', `${insets.top}px`);
      this.renderer.setStyle(this.notchOverlayElRef.nativeElement, 'top', `${-insets.top}px`);
    });

    this.showTranslationKeyToggle = !!sessionStorage.getItem(sessionKeys.displayToggle);
    this.checkWebGlSupport();
    this.initCustomIcons();

    this.addRouterSubscriptions();

    this.handlePreventVideoLoadQuery();

    this.settingsService.getSettings()
      .pipe(
        filter(data => !!data)
      )
      .subscribe((value: SettingsModel) => {
        this.settings = value;
        this.insertAnalyticsTags();
        // convert to boolean
        if (!!+value.security.preventImageDownload) {
          this.renderer.addClass(document.body, 'image-download-block');
        }

        const colorTheme = value.general.colorTheme;
        const availabelThemes = Object.values(ColorTheme);
        const theme = availabelThemes.indexOf(colorTheme) !== -1 ? colorTheme : ColorTheme.LIGHT;
        this.renderer.addClass(document.body, `theme-${theme}`);
        this.initializeChatIdentifier();
        this.initializeCookieConsent();
        this.initializeLocale();
        this.currentBranchActiveCheck();
        this.bioAuthService.initialize();
      });

    let isOnlineEmitted = false;
    this.networkService.isOnline().subscribe(online => {
      if (online) {
        this.storage.get('postPutRequestQueue').pipe(take(1)).subscribe(async res => {
          const requests = res as any[];

          if (isOnlineEmitted && !requests?.length) {
            this.toastr.info('You are back online!');
          }

          isOnlineEmitted = true;

          if (requests?.length && !!this.profileService.getCurrentProfileValue()) {
            let remainingRequests = requests.slice();
            const syncMessage = this.toastr.info('You are back online! Syncing offline process, please wait...');
            try {
              for (const request of requests) {
                await this.httpService.post(
                  request.url,
                  request.data,
                  request.withCredentials,
                  request.noHeaders,
                  new HttpHeaders(request.additionalHeaders.headers)
                ).toPromise();
                remainingRequests = remainingRequests.slice(1);
                await this.storage.set('postPutRequestQueue', remainingRequests).toPromise();
              }
            } catch (error) {
              this.toastr.error('An error has occured during syncing your offline process.');
              syncMessage.toastRef.close();
              throw error;
            }

            syncMessage.toastRef.close();

            this.gamificationService.checkForAchievementNotifications();
            this.certificateService.checkForUnreadCertificates();

            this.toastr.success('Your offline process has been synced successfully.');
          }
        });
      } else {
        this.toastr.error('You don\'t have internet connection. Your process will be synced when internet connection is back.');
        isOnlineEmitted = true;
      }
    });

    if (window.innerWidth < 800) {
      this.toastr.toastrConfig.timeOut = 2000;
    }
  }

  ngAfterViewInit() {
    const appLoadingEl = this.document.getElementById('app-loading');
    this.renderer.removeChild(appLoadingEl.parentNode, appLoadingEl);
  }

  @HostListener('window:focus')
  public checkForSessionChanged(): void {
    const sessionChanged = this.authService.checkForSessionChanged();

    if (sessionChanged) {
      window.location.reload();
    }
  }

  private handlePreventVideoLoadQuery(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const preventVideoLoad = urlSearchParams.has('preventVideoLoad');

    if (!preventVideoLoad) {
      return;
    }

    this.storageService.setItem(this.storageKeys.PREVENT_VIDEO_LOAD, true);
  }

  private initializeChatIdentifier(): void {
    const hostArr = window.location.host.split('.');
    const sub = hostArr[0];
    let chatName;
    if (hostArr.length > 1) {
      chatName = sub === 'www' ? `${hostArr[1]}-dashboard` : `${sub}-dashboard`;
    } else {
      chatName = `${sub}-dashboard`;
    }

    this.disqusChatService.identifier = chatName;
    this.disqusChatService.title = `${this.settings.general.platformName} chat`;
  }

  private initCustomIcons() {
    const icons = [
      'explore', 'search', 'sitemap', 'library', 'book', 'quiz-book', 'star', 'check-square', 'check-alt',
      'promotion-arrow-left', 'promotion-arrow-right', 'promotion-arrow-down', 'badge', 'message', 'send',
      'smile', 'video', 'logout', 'edit', 'delete', 'clock', 'clock-alt', 'calendar', 'class', 'course',
      'module', 'lesson', 'quiz', 'info', 'language-switcher', 'lock', 'lock-alt', 'progress-bg', 'progress-bg-alt',
      'answer-review', 'upload-file', 'skills', 'comments', 'fullscreen', 'info-circle', 'hourglass', 'document', 'plus',
      'minus', 'copy', 'date-picker', 'time', 'video-list', 'webinar', 'webinar-series', 'certificate-star', 'visibility',
      'visibility-off', 'download', 'tick-square', 'close-square', 'arrow-right', 'settings', 'bin', 'microphone', 
      'certificate-indicator', 'badge-star', 'exclamation-alt', 'card', 'select-arrow', 'branch', 'list', 'grid',
      'notification', 'duration', 'participants', 'folder-content', 'legal-docs-accepted', 'legal-docs-accepted-papers',
      'legal-docs-pending', 'legal-docs-pending-papers', 'browser', 'forum', 'badge-alt', 'play-outline',
      'achievement-alt', 'play', 'calendar-add', 'cup', 'carousel-arrow-right', 'carousel-arrow-left'
    ];

    icons.forEach(key => {
      this.matIconRegistry.addSvgIcon(
        `lms-${key}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${key}.svg`)
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.next();
    this.subscriptions.unsubscribe();
  }

  private addAppOpenListener() {
    // Initialize essential native services
    this.initializeNativeServices();

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = new URL(event.url);

        const slug = `${url.pathname}${url.search}`;

        if (slug) {
          this.inAppBrowserService.closeSystemBrowser();  
          this.router.navigateByUrl(slug);
        }
      });
    });

    App.addListener('appStateChange', async (state) => {
      this.zone.run(() => {
        if (state.isActive) {
          this.liveUpdateService.performManualNativeAppUpdate();
        }
      });
    });
  }

  private addRouterSubscriptions(): void {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => this.settings && event instanceof NavigationEnd),
        skip(1)
      )
      .subscribe(event => {
        this.initializeChatIdentifier();
      });
  }

  private addSubscriptionOnCookieConsent(): void {
    this.cookieConsentService.getCookieConsentAgreement()
      .pipe(
        filter(() => {
          return !this.analyticsScriptInitialized && this.cookieConsentService.isCookieConsentAgreed(CookieCategory.ANALYTICS);
        }),
        first()
      )
      .subscribe(() => this.insertAnalyticsTags());
  }

  private insertAnalyticsTags(): void {
    const cookieAgreed = this.cookieConsentService.isCookieConsentAgreed(CookieCategory.ANALYTICS);

    if (!cookieAgreed) {
      this.addSubscriptionOnCookieConsent();
      return;
    }

    // Google Analytics Tag
    const { trackingId, tagManagerId } = this.settings.googleAnalytics || {};
    this.googleAnalyticsService.initGoogleAnalytics(trackingId, tagManagerId);

    const { siteId, trackingUrl, mode, scriptUrl } = this.settings.matomoAnalytics;
    if (
      (siteId && trackingUrl && mode === MatomoModeEnum.BASIC)
      || (scriptUrl && mode === MatomoModeEnum.TAG_MANAGER)
    ) {
      const config = mode === MatomoModeEnum.BASIC
        ? { siteId, trackerUrl: trackingUrl }
        : { scriptUrl };

      this.matomoInitializer.initializeTracker(config as any);
    }

    this.analyticsScriptInitialized = true;
  }

  private initializeCookieConsent(): void {
    this.legalCenterService.getDocuments({ cookieConsentPolicy: 1 })
      .subscribe(data => {
        const documents = data.items.filter(item => item.cookieConsentPolicy);
        this.cookieConsentService.init(this.settings, documents);
      });
  }

  private checkWebGlSupport(): void {
    const isEnabled = this.isWebGLEnabled();

    if (isEnabled) {
      return;
    }

    this.dialog.open(WarningModalComponent, {
      panelClass: ['warning-dialog'],
      data: {
        title: 'COMMON.problem_detected',
        description: 'WARNING.unfortunately_we_detect_issues_with_browser',
        btnText: 'COMMON.i_understand'
      }
    })
  }

  private isWebGLEnabled(): boolean {
    if (!window.WebGLRenderingContext) {
      return false;
    }
  
    try {
      var canvas = document.createElement('canvas'); 

      return !!canvas.getContext('webgl') || !!canvas.getContext('experimental-webgl');
     } catch(e) {
       return false;
     }
  }

  private currentBranchActiveCheck(): void {
    if (this.settings.branch.currentBranch && (!this.settings.branch?.enabled || !this.settings.branch?.currentActive)) {
      this.branchService.setCurrentBranch(null);
      window.location.replace(environment.frontBaseDomain);
    }
  }

  private initializeLocale(): void {
    this.localeService.setLocale(this.settings.general.locale);
  }

  private initializeNativeServices(): void {
    this.setKeyboardResizeMode();
    this.disableUserZoom();
    this.nativeUpdateService.init();
    this.pushNotificationService.addNotificationListeners();
    this.navigateService.toggleSwipeNavigation(true);
  }

  private setKeyboardResizeMode() {
    const isKeyboardPluginSupported = Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Keyboard');
    if (!isKeyboardPluginSupported) {
      return;
    }

    Keyboard.setResizeMode({ mode: KeyboardResize.Body })
  }

  private disableUserZoom(): void {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    const existingMeta = this.meta.getTag('name="viewport"');
    if (!existingMeta) {
      return;
    }

    this.meta.updateTag({ name: 'viewport', content: `${existingMeta.content}, user-scalable=no` });
  }
}
